import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Loader from "../../components/tabelloader";
import jwt_decode from "jwt-decode";
import config from "../../config/index";
import ThemeMaker from "../../components/thememaker";
var $ = require('jquery');

const UserList = () => {
    const {baseurl} = config
    const token: any = sessionStorage.getItem("isLogedIn");
    const decoded: any = jwt_decode(token);
    const { user } = decoded.data.permission;

    const history = useHistory();
    const [userList, setUserList] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [loading, setloading] = useState(true);
    const closeModel: any = useRef();

    const [error, setError] = useState('');
    const [_id, setId] = useState("");

    const getUserList = async () => {
        try {
            const { data: { data } } = await axios({
                url: `${baseurl}/user/list`,
                method: "GET",
                headers: {
                    Authorization: ` ${token}`
                },
            });
            setUserList(data.filter((item: any) => item._id !== decoded.data._id).filter((item: any) => item._id !== '62a739cacf61d9b5638ab9fd'))
            setloading(false);
            setTimeout(() => {
                $('#dataTable').DataTable()
            }, 1000)
        } catch (error) {
            setloading(false);
            setError("error");
        }
    }
    const deleteUser = async () => {
        // $("#exampleModal").hide();
        //  alert(_id);
        try {
            setDeleteLoading(true);
            const { data } = await axios({
                url: `${baseurl}/user/delete`,
                method: "DELETE",
                headers: {
                    Authorization: ` ${token}`
                },
                data: { _id }
            });
            setDeleteLoading(false);
            //$("#closebtn").click();
            setUserList(userList.filter((itm: any) => itm._id !== _id));
            getUserList();
            closeModel.current.click()
        } catch (error) {
            setDeleteLoading(false);
        }
    }
    const redirectToUserPage = (_id: any) => {
        const user = userList.find((item: any) => item._id === _id);
        localStorage.setItem('user', JSON.stringify(user));
        history.push(`/user/${_id}`);
    }
    useEffect(() => {
        getUserList()
    }, [])
    return (
        <>
            <ThemeMaker>
                <div className="card-body">
                    <div className="table-responsive">
                        {
                            loading ? <Loader /> : (
                                <table className="table table-bordered" id="dataTable" width="100%" >
                                    <thead>
                                        <tr>
                                            <th>Sn</th>
                                            <th>Profile</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>User Type</th>
                                            <th>Gender</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tfoot>

                                    </tfoot>
                                    <tbody>
                                        {
                                            userList.map((item: any, index) => (
                                                <tr key={item._id} >
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <div>
                                                            <img src={`${item.profile ? item.profile : '/img/undraw_profile.svg'}`} alt="noimh" style={{ height: "50px" }} />
                                                        </div>
                                                    </td>
                                                    <td>{item?.name}</td>
                                                    <td>{item?.email}</td>
                                                    <td>{item?.mobile}</td>
                                                    <td>{item?.userType}</td>
                                                    <td>{item?.gender}</td>
                                                    <td>
                                                        <div className="row" style={{ width: "120px" }} >
                                                            <div className="col-md-12">
                                                                {
                                                                    decoded.data.isAdmin === "true" ? (
                                                                        <Link to={`#`} onClick={() => redirectToUserPage(item._id)} className="btn btn-primary btn-circle">
                                                                            <i className="fas fa-exclamation-triangle"></i>
                                                                        </Link>
                                                                    ) : user.update ? <Link to={`#`} onClick={() => redirectToUserPage(item._id)} className="btn btn-primary btn-circle">
                                                                        <i className="fas fa-exclamation-triangle"></i>
                                                                    </Link> : null
                                                                }
                                                                {
                                                                    decoded.data.isAdmin === "true" ? (
                                                                        <button className="btn btn-danger btn-circle ml-2"
                                                                            data-toggle="modal" data-target="#exampleModal"
                                                                            onClick={() => setId(item._id)}
                                                                        >
                                                                            <i className="fas fa-trash"></i>
                                                                        </button>
                                                                    ) : user.delete ? <button className="btn btn-danger btn-circle ml-2"
                                                                        data-toggle="modal" data-target="#exampleModal"
                                                                        onClick={() => setId(item._id)}
                                                                    >
                                                                        <i className="fas fa-trash"></i>
                                                                    </button> : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            )
                        }
                    </div>
                </div>
                <div className="modal fade" id="exampleModal" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center"></h5>
                                <button type="button" ref={closeModel} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure want to delete !</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger"
                                    onClick={deleteUser}
                                >
                                    {
                                        deleteLoading ? "Loading..." : "Delete"
                                    }

                                </button>
                                {
                                    deleteLoading ? null : (
                                        <button type="button" id="closebtn" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}

export default UserList;