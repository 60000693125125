
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link } from "react-router-dom";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
// import { tokenDecode } from "../../utils/helper";
// import { config } from "../../utils/config";
import '../../components/table.css';
import style from "../../components/Search&Options/Search&Options.module.css";
import CircularProgress from '@mui/material/CircularProgress';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { css, styled } from '@mui/material/styles';
import moment from "moment";
import { AgChartsReact } from 'ag-charts-react';
import { useState, useEffect, useRef, CSSProperties } from "react";
import Styles from "./style.module.css"
import jwt_decode from "jwt-decode";
import { Paginated } from "../../components/Paginated";
import config from "../../config/index";
var $ = require('jquery');

const CustomStyles = {
    Status: {
        display: 'grid',
        justifyItems: "stretch",
        alignItems: "center",
    } as CSSProperties,
    graphStyle: {
        fontSize: "13px",
        color: "grey",
        fontWeight: "600"
    } as CSSProperties,
    graphIconStyle: {
        fontSize: "13px",
        color: "grey",
        fontWeight: "600"
    } as CSSProperties,
    mainGraphStyle: {
        width: "100%",
        flexWrap: "wrap",
        gap: "20px"
    } as CSSProperties,
    mobileBoxes: {
        width: "45%",
        boxShadow: "grey 3px 3px 6px 1px",
        border: "1px solid grey;",
        borderRadius: "10px",
        background: "white",
    } as CSSProperties,
    counti: {
        fontSize: "29px",
        color: "black",
        fontWeight: "bold"
    } as CSSProperties,
    AssigStyle: {
        color: "blue",
    } as CSSProperties,
    PublishStyle: {
        color: "red"
    } as CSSProperties,
    CheckStyle: {
        color: "green"
    } as CSSProperties,
    HowToStyle: {
        color: "lightgreen"
    } as CSSProperties,
}

const Issues = () => {
    const {baseurl} = config
    const token: any = sessionStorage.getItem("isLogedIn");
    const decoded: any = jwt_decode(token);
    const [issuesList, setIssuesList] = useState([]);
    const [SearchList, setSearchList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [issueDate, setissueDate]: any = useState([]);
    const { data } = decoded;
    let sellerProviderId = data?.seller_data?.provider_id



    const [issellerSelected, setissellerselected] = useState(false);
    const { permission: { category, order, product, role, seller, tax, user } } = data;
    const [sellerList, setSellerList] = useState([]);
    const current_seller_id = decoded?.data?.user?.seller_id;
    var count = 1;
    // const shipping_address = JSON.parse(_orderDetail?.shipping_address);
    const fetchIssuesList = async () => {
        setIsLoading(true);
        try {
            const { data: { data }, } = await axios({
                url: `${baseurl}/getAllIssue`,
                method: "GET",
                headers: {
                    Authorization: token
                },
            });
            if (data && data.length > 0) {
                data.sort((a: any, b: any) => {
                    const dateA: any = new Date(a.createdAt);
                    const dateB: any = new Date(b.createdAt);
                    return dateB - dateA;
                });
                setIssuesList(data);
                setSearchList(data);
            } else {
                setIssuesList([]);
                setSearchList([]);
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error", error);
            setIsLoading(false);
        }
    }

    const filterBySearch = (event: any) => {
        const querySearch = event.target.value;
        let SearchFiltere = issuesList.filter((item: any) => {
            return (
                item?.issue_id.toLowerCase().includes(querySearch.toLowerCase())
            );
        });
        setSearchList(SearchFiltere);

    };

    // Fields Mapping, Table  Behalf of Category to Sub-category
    const subCategoryMap: any = {
        /* ITEM */
        ITM01: "Missing items",
        ITM02: "Quantity Issue",
        ITM03: "Item mismatch",
        ITM04: "Quality issue",
        ITM05: "Expired item",
        /* FULFILLMENT */
        FLM01: "Wrong delivery address",
        FLM02: "Delay in delivery",
        FLM03: "Delayed delivery",
        FLM04: "Packaging",
        FLM05: "Buyer not found",
        FLM06: "Seller not found",
        FLM07: "Package info mismatch",
        FLM08: "Incorrectly marked as delivered",
        /* ORDER */
        ORD01: "Order not received",
        ORD02: "Quality issue",
        ORD03: "Delayed delivery",
        ODR04: "Invoice missing",
        /* AGENT */
        AGT01: "Invoice missing",
        AGT02: "Buyer behavioral issue",
        /* PAYMENT */
        PMT01: "Refund not received",
        PMT02: "Underpaid",
        PMT03: "Overpaid",
        PMT04: "Not paid",
    };
    const COLUMNS = [
        {
            Header: "Sn No",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: true,
            sticky: "left"
        },
        {
            Header: "Ticket No",
            accessor: (item: any) => (item?.issue_id),
            sticky: "left"
        },
        {
            Header: "Issues Type",
            accessor: (item: any) => (item?.issue_type),
            sticky: "left"
        },
        {
            Header: "Issue Created Date",
            accessor: (item: any) => (item?.createdAt ? <span className="text-nowrap">{moment(item?.createdAt).format("DD-MM-YYYY hh:mm:ss")}</span> : ''),
            sticky: "left"
        },
        {
            Header: "Ageing (in days)",
            accessor: (row: any) => {
                if (row.status === "PROCESSING" || row.status === "RESLOVING" || row.status === "RESOLVED" || row.status === "CLOSED" || row.status === "CLOSE") {
                    let date1 = moment(row.createdAt);
                    let date2 = moment(row.updatedAt);
                    const days = date2.diff(date1, 'days');
                    console.log("days", days)
                    return days
                } else {
                    let date_1 = moment(row.createdAt);
                    let date_2 = moment(new Date());
                    const days = date_2.diff(date_1, 'days')
                    return days
                }
            },
            sticky: "left"
        },
        {
            Header: "Closed Date",
            accessor: (item: any) => (item?.updatedAt ? moment(item?.updatedAt).format("DD-MM-YYYY hh:mm:ss") : ""),
            sticky: "left"
        },
        {
            Header: "Category",
            accessor: (item: any) => (item?.category),
            sticky: "left"
        },
        {
            Header: "Sub Category",
            accessor: (item: any) => (item.sub_category ? <span className={`text-nowrap`}>{subCategoryMap[item?.sub_category] || ""}</span> : ""),
            sticky: "left"
        },
        {
            Header: "Status",
            sticky: "left",
            accessor: (item: any) => {
                const statusMapping: any = {
                    "Closed": "btn-outline-danger",
                    "CLOSED": "btn-outline-danger",
                    "RESOLVED": "btn-outline-success",
                    "PROCESSING": "btn-outline-warning",
                    "NEED-MORE-INFO": "btn-outline-warning",
                };
                const statusClass = statusMapping[item?.status] || "badge-warning";
                return <span style={CustomStyles?.Status} className={`badge text-reset btn ${statusClass}`}>{item?.status}</span>;
            },
        },
        {
            Header: "Action",
            filterable: true,
            sticky: "left",
            Cell: ({ row }: any) => (<Link to={{ pathname: '/onissue', state: { tkt: row?.original.issue_id } }} style={CustomStyles?.Status} className="badge badge-outline-dark btn btn-outline-primary">View</Link>),
        },
    ];
    const getSellerList = async () => {
        try {
            const { data } = await axios({
                url: `${baseurl}/getsellerlist`,
                method: "GET",
                headers: {
                    Authorization: token
                },
            });
            if (data.status === 200) {
                // console.log(data.data)
                setSellerList(data.data);
            }
        } catch (error) {
            console.log("error", error);
        }
    }
    const inputHandler = async (e: any) => {
        var optionvalue = e.target.value;
        if (optionvalue === "") {
            fetchIssuesList();
        } else {
            let data = [...issuesList]
            setSearchList(data.filter((item: any) => item.order_details.provider_id == optionvalue));
            getcounts(optionvalue);
            setissellerselected(true)
        }
    };

    const [counts, setcounts] = useState({
        Closed_Tickets: 0,
        Processing_Tickets: 0,
        Resolved_Tickets: 0,
        Total_tickets: 0,
        Seller_Closed_Tickets: 0,
        Seller_Processing_Tickets: 0,
        Seller_Resolved_Tickets: 0,
        Seller_Total_Tickets: 0
    })

    const [series, setSeries] = useState([
        {
            name: 'Open Tickets',
            data: [50, 11, 10, 40]
        },
        {
            name: 'Closed Tickets',
            data: [11, 30, 40, 76]
        }
    ]);
    const [pieChartData, setpieChartData] = useState({
        "resolved": 0,
        "open": 0,
        "closed": 0,
        // "processing": 0
    })
    const [options, setOptions]: any = useState({
        data: [
            { asset: 'Resolved', amount: pieChartData.resolved },
            { asset: 'Open', amount: pieChartData.open },
            { asset: 'Closed', amount: pieChartData.closed },
            // { asset: 'Processing', amount: pieChartData.processing },

        ],
        width: 300,

        series: [
            {
                type: "pie",
                angleKey: "amount",
                calloutLabelKey: "asset",
                sectorLabelKey: "amount",
                sectorLabel: {
                    color: "white",
                    fontWeight: "bold",
                    formatter: ({ value }: any) => `${(value)}`,
                },
            },
        ],

    });

    async function getcounts(sellerProviderId: string) {
        try {
            const { data } = await axios({
                url: `${baseurl}/v1/getAnalyticData`,
                // url: `/igm/get_tickets_count`,
                method: "GET",
                headers: {
                    Authorization: token
                },
                // data: { provider_id: sellerProviderId }

            });

            setcounts(data?.data);
            setpieChartData(data?.data);
            setOptions((prev: any) => {
                return {
                    ...prev,
                    data: [
                        { asset: 'Resolved', amount: data.data.Resolved_Tickets },
                        { asset: 'Open', amount: data.data.Processing_Tickets },
                        { asset: 'Closed', amount: data.data.Closed_Tickets },
                        // { asset: 'Processing', amount: data.data.cancelled },
                    ]
                }
            })
        } catch (error) {
            console.log("error", error);
        }
    }

    const getfilterOption = async (optionvalue: any) => {
        setIsLoading(true);
        try {
            const providerID = current_seller_id === 1 ? "" : sellerProviderId;
            const { data } = await axios({
                url: `${baseurl}/v1/getCount`,
                data: {
                    provider_id: providerID,
                    status: optionvalue
                },
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('us_st_d')}`
                }
            });
            if (data?.statusCounts && data?.statusCounts?.length > 0) {
                data?.statusCounts?.sort((a: any, b: any) => {
                    const dateA: any = new Date(a.createdAt);
                    const dateB: any = new Date(b.createdAt);
                    return dateB - dateA;
                });
                setIssuesList(data?.statusCounts);
                setSearchList(data?.statusCounts);
            } else {
                setIssuesList([]);
                setSearchList([]);
            }
            setIsLoading(false);
        } catch (error) {
            console.log("error", error);
            setIsLoading(false);
        }
    }
    const handlerFilterOption = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        const optionValue = e.target.value;
        optionValue === "" || optionValue === null ? fetchIssuesList() : getfilterOption(optionValue);
    };

    useEffect(() => {
        fetchIssuesList();
        getSellerList();
        if (current_seller_id === 1) {
            getcounts("");
        } else {
            getcounts(sellerProviderId);
        }
    }, []);

    const styles = {
        countMain: css`
          background-color: white;
          border-radius: 8px;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
          border: 1px solid #ccc;
          padding: 12px;
          display: flex;
          flex-direction: column;
        `,
        mobileBoxes: css`
          display: flex;
          flex-direction: column;
          padding: 8px;
          padding-left: 12px;
        `,
        countText: css`
          margin-top: 8px;
          margin-bottom: 8px;
        `,
        countDescription: css`
          font-size: 13px;
          color: grey;
          font-weight: 600;
        `,
    };
    return (
        <>
            <ThemeMaker>
                <div className="row w-100 m-0 mt-1">
                    <div className="col-md-12 p-2">
                        <div className="">
                            <div className={`d-flex justify-content-between flex-md-row ${Styles.main}`} style={{ gap: "5px" }}>
                                <div className={`p-3 rounded bg-white border ${Styles.ticketlist}`}>
                                    <div className="d-flex align-items-center my-2 justify-content-between">
                                        <p className="genericlabel ps-1 m-0">Ticket List</p>
                                        <div className="col-sm-8 col-md-8 d-flex justify-content-end">
                                            <div className={`d-flex py-1 ${Styles.search_filter}`}>
                                                <div className="">
                                                    <select name="" id="" className={`form-control ${Styles?.fixed_width}`} onChange={(e: any) => handlerFilterOption(e)}>
                                                        <option value="" disabled selected>Search By</option>
                                                        <option value="" >Search All</option>
                                                        <option value="PROCESSING">Processing</option>
                                                        <option value="RESOLVED">Resolved</option>
                                                        <option value="CLOSED">Closed</option>
                                                    </select>
                                                </div>
                                                <div className="">
                                                    {current_seller_id === 1 ? (
                                                        <select name="" id="" className={`form-control ${Styles?.fixed_width}`} onChange={(e: any) => inputHandler(e)}>
                                                            <option value="" disabled selected>Select Seller</option>
                                                            {sellerList.map((item: any) => (
                                                                <option value={item.provider_id}>{item?.company_name}</option>
                                                            ))}
                                                        </select>
                                                    ) : null}
                                                </div>
                                                <div className="">
                                                    <input
                                                        type="search"
                                                        className={`form-control ${Styles?.fixed_width}`}
                                                        placeholder="Enter Ticket ID"
                                                        onChange={filterBySearch}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ height: "70vh", overflow: "scroll" }}>
                                        {!isLoading ? (
                                            SearchList.length > 0 ? (
                                                <Paginated data={SearchList} columns={COLUMNS} />
                                            ) : (
                                                <p className="text-center">No Tickets Yet!!</p>
                                            )
                                        ) : (
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                                                <CircularProgress />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={`bg-white rounded shadow border p-3  d-flex flex-column ${Styles.countmain}`}>
                                    <div className="d-flex justify-content-around my-1" style={CustomStyles?.mainGraphStyle}>
                                        <div className={`d-flex flex-column p-2 pl-3`} style={CustomStyles.mobileBoxes}>
                                            {/* <AssignmentTurnedInIcon style={CustomStyles?.AssigStyle} /> */}
                                            <i className="fa fa-check-circle" aria-hidden="true"></i>
                                            <p className={`mt-1 mb-1 ${CustomStyles?.counti}`}>{counts?.Total_tickets ? counts?.Total_tickets : "0"}</p>
                                            <p className="m-0 p-0" style={CustomStyles?.graphStyle}>Total Tickets</p>
                                        </div>
                                        <div className={`d-flex flex-column p-2 pl-3`} style={CustomStyles.mobileBoxes}>
                                            {/* <PublishedWithChangesIcon style={CustomStyles?.PublishStyle} /> */}
                                            <i className="fa fa-envelope-open" aria-hidden="true"></i>
                                            <p className={`mt-1 mb-1 ${CustomStyles.counti}`}>{counts.Processing_Tickets ? counts.Processing_Tickets : "0"}</p>
                                            <p className="m-0 p-0" style={CustomStyles?.graphStyle}>Open Tickets</p>
                                        </div>
                                        <div className={`d-flex flex-column p-2 pl-3`} style={CustomStyles.mobileBoxes}>
                                            {/* <CheckCircleIcon style={CustomStyles?.CheckStyle} /> */}
                                            <i className="fa fa-cogs" aria-hidden="true"></i>
                                            <p className={`mt-1 mb-1 ${CustomStyles.counti}`}>{counts?.Closed_Tickets ? counts?.Closed_Tickets : "0"}</p>
                                            <p className="m-0 p-0" style={CustomStyles?.graphStyle}>Closed Tickets</p>
                                        </div>
                                        <div className={`d-flex flex-column p-2 pl-3 `} style={CustomStyles.mobileBoxes}>
                                            {/* <HowToRegIcon style={CustomStyles?.HowToStyle} /> */}
                                            <i className="fa fa-thumbs-up" aria-hidden="true"></i>
                                            <p className={`mt-1 mb-1 ${CustomStyles.counti}`}>{counts?.Resolved_Tickets ? counts?.Resolved_Tickets : "0"}</p>
                                            <p className="m-0 p-0" style={CustomStyles?.graphStyle}>Resolved Tickets</p>
                                        </div>
                                    </div>
                                    {options?.data.some((item: any) => item.amount !== 0) && <div className={` justify-content-center mt-5 ${Styles.piechart}`}>
                                        <AgChartsReact options={options} />
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}

export default Issues;