import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { format } from 'date-fns'
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "../orderlist/orderlistPopus.css";
import Loader from "../../components/tabelloader";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import { BasicTable } from '../../components/Table';
import { Paginated } from "../../components/Paginated";
import '../../components/table.css';
import config from "../../config/index";
var $ = require("jquery");

const OrderList = () => {
  // filter
  const {baseurl} = config
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const [popup, setPopup] = useState(false);
  const [orderStatusPopup, setOrderStatusPopup] = useState(false);
  const [orderFulfillmentPopup, setOrderFulfillmentPopup] = useState(false);
  const [orderPopup, setOrderPopup] = useState([]);

  const token: any = sessionStorage.getItem("isLogedIn");
  const decoded: any = jwt_decode(token);
  //console.log(decoded.data.permission.user);
  const { user, product, order } = decoded.data.permission;

  const history = useHistory();
  const [orderList, setOrderList] = useState([]);
  const [downloadcsv, setDownloadcsv] = useState({});

  const [loading, setloading] = useState(true);
  const closeModel: any = useRef();

  const [error, setError] = useState("");
  const [_id, setId] = useState("");

  const [fullFillmentStatus, setFullFillmentStatus] = useState("");
  const [status, setstatus] = useState();
  const [orderid, setorderid] = useState("");
  const [dynamicValue, setDynamicValue] = useState("In-progress");
  const [context, setcontext] = useState("");

  const handleClickPop = async (id: any) => {
    setPopup(!popup);
    try {
      const { data } = await axios({
        url: `${baseurl}/orders/order_details`,
        method: "POST",
        headers: {
          Authorization: ` ${token}`
        },
        data: {
          order_id: id,
        },
      });
      // console.log("sas => ", data);
      if (data.status === 200) {
        setOrderPopup(data.data.reverse());
      } else {
        toast.error("404 not found !");
      }
      setloading(false);
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 1000);
      // console.log("data>>>>>>", data);
    } catch (error) {
      setloading(false);
    }
  };
  const closePopup = () => {
    setPopup(false);
  };

  const getOrderList = async () => {
    try {
      const {
        data: { data },
      } = await axios({
        url: `${baseurl}/orders/npdata`,
        method: "GET",
        headers: {
          Authorization: ` ${token}`
        },
      });
      setOrderList(data.reverse());
      setFilteredData(data.reverse());
      // console.log("data 89", data);
      setloading(false);
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 1000);
    } catch (error) {
      setloading(false);
      setError("error");
    }
  };


  const getcsvDownload = async () => {
    axios.get(`${baseurl}/orders/exportcsv`, {
      method: "GET",
      headers: {
        Authorization: ` ${token}`
      },
    })
      .then(response => {
        console.log("sdfghjk", response.data);
      })
      .catch(error => {
        // Handle any errors here
        console.error(error);
      });
  };


  // Filter
  const handleStartDateChange = (event: any) => {
    setStartDate(event.target.value);
    filterData(event.target.value, endDate);
  };

  const handleEndDateChange = (event: any) => {
    setEndDate(event.target.value);
    filterData(startDate, event.target.value);
  };
  // filter By Dates
  const filterData = (start: any, end: any) => {
    const startFilter = start ? new Date(start) : null;
    const endFilter = end ? new Date(end) : null;
    const filtered = orderList.filter((item: any) => {
      const itemDate = new Date(item.createdAt);
      return (
        (!startFilter || itemDate >= startFilter) && (!endFilter || itemDate <= endFilter)
      );
    });
    setFilteredData(filtered);
  };

  const filterBySearch = (event: any) => {
    const querySearch = event.target.value;
    let SearchFiltere = orderList.filter((item: any) => {
      return (
        item?.response?.message?.order?.billing.name.toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.status.toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.ondcorderid?.toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.awbid?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.response.message.order.quote.breakup[0].title?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.response?.message?.order?.billing?.address?.area_code?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.response?.message?.order?.payment?.status?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.response?.context?.bap_id?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.delivery_city?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.shopifyorderef?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.sku?.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        `${item?.response?.message?.order.billing.name.slice(0, 4)}-${item?.response?.message?.order?.billing?.phone?.slice(-5)}`.toString().toLowerCase().includes(querySearch.toLowerCase()) ||
        item?.response?.context?.transaction_id?.toString().toLowerCase().includes(querySearch.toLowerCase())
      );
    });
    setFilteredData(SearchFiltere);
  };
  // filter End

  const DownloadCsv = async () => {
    // console.log("file download")
    try {
      const {
        data: { data },
      } = await axios({
        url: `${baseurl}/orders/exportcsv`,
        // url:"localhost:9095/orders/exportcsv",
        method: "GET",
        headers: {
          "Content-Type": "application/csv",
          Authorization: ` ${token}`

        },
      });

      console.log(data);
      setDownloadcsv(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderList();
    //  DownloadCsv();
  }, []);

  useEffect(() => {
    if (status === "Accepted") {
      setFullFillmentStatus("Pending");
    }
    if (status === "In-progress") {
      setFullFillmentStatus("Order-picked-up");
    }
    if (status === "Completed") {
      setFullFillmentStatus("Order-delivered");
    }
    if (status === "Cancelled") {
      setFullFillmentStatus("Cancelled");
    }
  }, [status]);

  const changeStatus = async () => {
    try {
      const { data } = await axios({
        url: `${baseurl}/orders/orderstatus`,
        method: "PUT",
        headers: {
          Authorization: ` ${token}`
        },
        data: {
          order_id: orderid,
          orderStatus: status,
          fullFillmentStatus: fullFillmentStatus,
        },
      });
      if (data.status === 200) {
        toast.success("Order status updated !");
        setOrderStatusPopup(false);
        changeStatusseller(orderid, context);
      } else {
        toast.error("404 not found !");
      }
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 1000);
    } catch (error) { }
  };

  const CancelItem = async (OrderId: any, item_id: any) => {
    // context.message_id = uuidv4();
    const contextdata = {
      order_id: OrderId,
      item_id,
      status: "Cancelled",
    };
    try {
      const { data } = await axios({
        url: `${baseurl}/orders/cancel-item`,
        method: "PUT",
        headers: {
          Authorization: ` ${token}`
        },
        data: contextdata,
      });
      // console.log("sas => ", data);
      toast.success("Status updated by seller app");
      // console.log("data>>>>>>", data);
    } catch (error: any) {
      // console.log("error", error);
      toast.error(`Error : ${error.message}`);
    }
  };

  const changeStatusseller = async (orderids: any, context: any) => {
    // console.log("orderId", orderids);
    context.message_id = uuidv4();
    const contextdata = await {
      context,
      message: {
        order_id: orderids,
      },
    };
    // console.log("contaxtdata 188", contextdata);
    try {
      const { data } = await axios({
        url: `${baseurl}/preprod/v1/status`,
        method: "POST",
        headers: {
          Authorization: ` ${token}`
        },
        data: contextdata,
      });
      // console.log("sas => ", data);
      toast.success("unsolicited Status updated by seller app");
      // console.log("data>>>>>>", data);
    } catch (error: any) {
      console.log("error", error);
      toast.error(`Error : ${error.message}`);
    }
  };

  const ChangeStatusPopup = (
    ondcorderid: any,
    orderStatus: any,
    context: any
  ) => {
    console.log(">>>>>>>>>>", orderStatus);
    setOrderStatusPopup(true);
    setorderid(ondcorderid);
    setstatus(orderStatus);
    setcontext(context);
    setDynamicValue(orderStatus);
  };

  const closePop = () => {
    setOrderStatusPopup(false);
  };

  const CancelOrder = async (shopifyorderid: any, ondcorderid: any) => {
    console.log("ondcorderid", ondcorderid);
    console.log("shopifyorderid", shopifyorderid);
    const confirmed = window.confirm(
      "Are you sure you want to cancel this order?"
    );
    if (confirmed) {
      try {
        const { data } = await axios({
          url: `${baseurl}/orders/cancelorder`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` ${token}`
          },
          data: {
            shopifyorderid: shopifyorderid,
          },
        });
        console.log("sas => ", data);
        if (data.status === 200) {
          toast.success("Order Cancelled");
        } else {
          toast.error("404 not found!");
        }
        console.log("data>>>>>>", data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const COLUMNS = [
    {
      Header: "Sn.",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>
      },
      disableFilters: true,
      sticky: "left"
    },
    {
      Header: "Network Order Id",
      accessor: "ondcorderid",
      sticky: "left"
    },
    {
      Header: "Order Create Date & Time",
      accessor: "createdAt",
      Cell: (item: any) => format(new Date(item.value), 'yyyy-MM-dd-kk:mm:ss'),
      sticky: "left"
    },
    {
      Header: "Customer Unique Identifier[First 4 Char of Buyer Name + Last 5 Digit of Ph]",
      accessor: (item: any) => (`${item?.response?.message?.order.billing.name.slice(0, 4)}-${item?.response?.message?.order?.billing?.phone?.slice(-5)}`),
      sticky: "left"
    },
    {
      Header: "Payment Status",
      accessor: "response.message.order.payment.status",
      sticky: "left"
    },
    {
      Header: "Price",
      accessor: "response.message.order.quote.breakup[0].price.value",
      sticky: "left"
    },
    {
      Header: "Total Order Value",
      accessor: "response.message.order.items[0].quantity.count",
      sticky: "left"
    },
    {
      Header: "Delivery City",
      accessor: "delivery_city",
      sticky: "left"
    }, {
      Header: "Inter-City",
      accessor: "intercity",
      sticky: "left"
    }, {
      Header: "Delivery Pincode",
      accessor: "response.message.order.billing.address.area_code",
      sticky: "left"
    },
    {
      Header: "Seller NP Type (MSN / ISN)",
      accessor: (a: any) => (a ? "ISN" : ""),
      sticky: "left"
    },
    {
      Header: "Seller NP Order Id",
      accessor: "shopifyorderef",
      sticky: "left"
    },
    {
      Header: "Order Status",
      accessor: "status",
      sticky: "left"
    },
    {
      Header: "Buyer NP Name",
      accessor: "response.context.bap_id",
      sticky: "left"
    },
    {
      Header: "Name of Seller",
      accessor: (a: any) => (a ? "The U Shop" : ""),
      sticky: "left"
    },
    {
      Header: "Network Transaction Id",
      accessor: "response.context.transaction_id",
      sticky: "left"
    },
    {
      Header: "SKU Name",
      accessor: "response.message.order.quote.breakup[0].title",
      sticky: "left"
    },
    {
      Header: "SKU Code",
      accessor: "sku",
      sticky: "left"
    },
    {
      Header: "Order Category (F&B/ Grocery/ Home & Decor)",
      accessor: (a: any) => (a ? "Grocery" : ""),
      sticky: "left"
    },
    {
      Header: "AWB No.",
      accessor: (a: any) => (a?.awbid),
      sticky: "left"
    }, {
      Header: "Logistics Service provider name",
      accessor: (item: any) => (item?.courier_company),
      sticky: "left"
    },
    {
      Header: "Warehouse Name",
      accessor: (a: any) => (a.status === "Accepted" || a.status === "In-progress" || a.status === "Completed") && 'JHAKAAS RETAIL PRIVATE LIMITED',
      sticky: "left"
    },
    {
      Header: "Warehouse Contact",
      accessor: (a: any) => (a.status === "Accepted" || a.status === "In-progress" || a.status === "Completed") && '18008330506',
      sticky: "left"
    },
    {
      Header: "Tracking URL",
      accessor: (item: any) => (item?.tracking_url || item?.trackurl),
      sticky: "left"
    },
    {
      Header: "Ready to Ship At Date & Time",
      accessor: (item: any) => (item?.status === "Cancelled" || item?.status === 'Accepted' || item?.status === 'Completed' || item?.status === 'In-progress') && item.ready_shipped_datetime ? format(new Date(item.ready_shipped_datetime), 'yyyy-MM-dd-kk:mm:ss') : "",
      sticky: "left"
    },
    {
      Header: "Shipped At Date & Time",
      accessor: (a: any) => (a.status === "In-progress" || a.status === "Completed") && a.shipped_datetime ? format(new Date(a.shipped_datetime), 'yyyy-MM-dd-kk:mm:ss') : "",
      sticky: "left"
    },
    {
      Header: "Delivered At Date & Time",
      accessor: (a: any) => (a.status === "Completed") && a.delivered_datetime ? format(new Date(a.delivered_datetime), 'yyyy-MM-dd-kk:mm:ss') : "",
      sticky: "left"
    },  
    {
      Header: "Cancelled At Date & Time",
      // accessor: (a: any) => (a.status === "Cancelled") && a.cancel_date ? format(new Date(a.cancel_date), 'yyyy-MM-dd-kk:mm:ss') : "",
      accessor: (a: any) => ((a?.status === "Cancelled") && a?.cancel_date) ? a.cancel_date : "",
      sticky: "left"
    },
    {
      Header: "Cancelled By (Buyer/ Seller/ Logistics)",
      accessor: (item: any) => (item?.cancelled_by),
      sticky: "left"
    },
    {
      Header: "Cancelled Reason Id",
      accessor: (a: any) => (a?.cancellation_reason_id),
      sticky: "left"
    },
    {
      Header: "Cancellation reason /retrun reason(Network description)",
      accessor: (a: any) => (a?.cancel_reasons),
      sticky: "left"
    },
    {
      Header: "Logistics Seller NP Name (For on-network delivery)",
      accessor: (a: any) => (a ? "OFF NETWORK" : ""),
      sticky: "left"
    },

    {
      Header: "Seller City",
      accessor: (a: any) => (a ? "Bangalore" : ""),
      sticky: "left"
    },
    {
      Header: "Seller Pincode",
      accessor: (a: any) => (a ? "560067" : ""),
      sticky: "left"
    },
    {
      Header: "Total Shipping Charges",
      accessor: (item: any) => (item?.response?.message?.order?.quote?.breakup?.slice(-1)[0]?.price?.value),
      sticky: "left"
    },
    {
      Header: "Total Order Value (Inc Shipping charges)",
      accessor: (item: any) => (item?.response?.message?.order?.quote?.price?.value),
      sticky: "left"
    },
    {
      Header: 'Orders Details',
      Cell: ({ row }: any) => (
        <button onClick={() => handleClickPop(row.original.ondcorderid)} className="btn btn-info btn-sm" data-target="#myModal" data-toggle="modal" >View</button>
      ),
    }
  ];
  console.log("filteredData", filteredData)

  return (
    <>
      <ThemeMaker>
        <div className="row">
          <div className="col-md-12 pl-4 pr-4">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <div className="row d-flex justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Order List
                  </h6>
                  {/* <button type="submit" className="btn btn-primary download "
                    style={{ marginLeft: "78%" }}
                    onClick={DownloadCsv}
                    value="download"
                  >
                  Download Sheet
                  </button> */}
                  <div>
                    <a href="/orders/exportcsv"
                      className="btn btn-primary" >Download csv
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="col-6">
                      <label htmlFor="start-date">Search Filter : </label>
                      <input type="search" className="form-control" placeholder="Search" id="start-date" onChange={filterBySearch} />
                    </div>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    <div className="col-6">
                      <label htmlFor="start-date"> Start Date : </label>
                      <input type="date" className="form-control" placeholder="Select a date" id="start-date"
                        value={startDate}
                        onChange={handleStartDateChange} />
                    </div>
                    <div className="col-5">
                      <label htmlFor="end-date"> End Date : </label>
                      <input type="date" className="form-control" value={endDate}
                        onChange={handleEndDateChange} id="end-date" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive table-striped table-bordered">
                  {loading ? (
                    <Loader />
                  ) : (
                    <Paginated data={filteredData.reverse()} columns={COLUMNS} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="modal fade" id="myModal" role="dialog">
            <div className="modal-dialog ">
              <div className="modal-content container">
                <div className="modal-header">
                  <h2>Orders Details</h2>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>

                <div className="modal-body">
                  <table
                    className="table table-bordered"
                    id="dataTable"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>Sn</th>
                        <th>Shopify OrderId</th>
                        <th>ONDC OrderId</th>
                        <th>Item Id</th>
                        <th>F_Code</th>
                        <th>F_Name</th>
                        <th>
                          Cancellation reason /retrun reason(Network
                          description)
                        </th>
                        <th>Cancelled At Date & time</th>
                        <th>Cancelled_by</th>
                        <th>Cancellation_reason_id</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tfoot></tfoot>
                    <tbody>
                      {orderPopup.map((item: any, index) => (
                        <tr key={item._id}>
                          <td>{index + 1}</td>

                          <td>{item?.shopifyorderid}</td>
                          <td>{item?.ondcorderid}</td>
                          <td>{item?.itemid}</td>
                          <td>{item?.f_code}</td>
                          <td>{item?.f_name}</td>
                          <td>{item?.cancellation_reason}</td>
                          <td>{item?.cancel_date}</td>
                          <td>{item?.cancelled_by}</td>
                          <td>{item?.cancellation_reason_id}</td>
                          <td>{item?.status}</td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {orderStatusPopup ? (
          <div className="orderStatuspopup text-center mx-auto p-5 border shadow bg-light position-fixed top-50 end-0 translate-middle-y">
            <div>
              <div className="selectStatus">
                <p>{`Update order : ${status}`}</p>
                <select
                  className="btn btn-secondary"
                  value={status}
                  onChange={(event: any) => {
                    // console.log("event", event);
                    setstatus(event.target.value);
                  }}
                >
                  <option value="Created" disabled={dynamicValue !== "Created"}>
                    Created
                  </option>
                  <option
                    value="Accepted"
                    disabled={
                      dynamicValue !== "Created" &&
                      dynamicValue !== "Accepted" &&
                      (dynamicValue === "In-progress" ||
                        dynamicValue === "Completed")
                    }
                  >
                    Accepted
                  </option>
                  <option
                    value="In-progress"
                    disabled={
                      dynamicValue !== "Created" &&
                      dynamicValue !== "In-progress"
                    }
                  >
                    In-progress
                  </option>
                  <option
                    value="Completed"
                    disabled={
                      dynamicValue !== "Accepted" &&
                      dynamicValue === "Completed"
                    }
                  >
                    Completed
                  </option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>
              <div className="selectStatus">
                <p className="m-2">{`Update fulfillment : ${fullFillmentStatus}`}</p>
                <select
                  className="btn btn-secondary"
                  value={fullFillmentStatus}
                  onChange={(event: any) => {
                    console.log("event", event);
                    setFullFillmentStatus(event.target.value);
                  }}
                >
                  <option value="Pending" disabled={status !== "Accepted"}>
                    Pending
                  </option>
                  <option
                    value="Order-picked-up"
                    disabled={status !== "In-progress"}
                  >
                    Order picked up
                  </option>
                  <option
                    value="Out-for-delivery"
                    disabled={status !== "In-progress"}
                  >
                    Out for delivery
                  </option>
                  <option
                    value="Order-delivered"
                    disabled={status !== "Completed"}
                  >
                    Order delivered
                  </option>
                  <option value="Cancelled" disabled={status !== "Cancelled"}>
                    Cancelled
                  </option>
                </select>
              </div>
            </div>
            <button
              className="btn btn-primary d-flex m-auto updateButton"
              type="submit"
              onClick={changeStatus}
            >
              Update
            </button>
            <button
              className="border-0 px-3 py-2 rounded-circle "
              onClick={closePop}
            >
              x
            </button>
          </div>
        ) : null}
      </ThemeMaker>
    </>
  );
};

export default OrderList;
