import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Loader from "../../components/tabelloader";
import jwt_decode from "jwt-decode";
import moment from "moment";
import CircularProgress from '@mui/material/CircularProgress';
import ThemeMaker from "../../components/thememaker";
import { Paginated } from "../../components/Paginated";
import config from "../../config/index";
var $ = require("jquery");

const myStatus:any = {
  2:"Open",
  3:"Pending",
  4:"Resolved",
  5:"Closed"
}
const RsfList = () => {
  const {baseurl} = config
  const token: any = sessionStorage.getItem("isLogedIn");
  const decoded: any = jwt_decode(token);
  //console.log(decoded.data.permission.user);
  const { user, product, order, support } = decoded.data.permission;
  const history = useHistory();
  const [rsfList, setRsfList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setloading] = useState(true);
  const closeModel: any = useRef();
  const [error, setError] = useState("");

  const getRsfList = async () => {
    try {
      const {
        data: { data },
      } = await axios({
        url: `${baseurl}/v1/trans_filter`,
        method: "GET",
         headers: {
            Authorization: token
        },
      });
    //   console.log()
      setRsfList(data);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log("error",error);
    }
  };

  // const ndate = new Date(orderList.createdAt);
  // const checkdate = ndate.toLocaleDateString()
  const redirectToUserPage = (_id: any) => {
    const product = rsfList.find((item: any) => item._id === _id);
    localStorage.setItem("product", JSON.stringify(product));
    history.push(`/product/${_id}`);
  };
  // FIlter Search
  const filterBySearch = (event: any) => {
    const querySearch = event.target.value.toLowerCase();
    const SearchFiltere = rsfList?.filter((item: any) => {
      const fields = ['issue_id', 'order_id', 'status'];
      return fields.some((field: any) =>
        (item?.[field]?.toLowerCase() || '').includes(querySearch)
      );
    });
    setFilteredData(SearchFiltere);
  };
  const COLUMNS = [
    {
      Header: "Sn No",
    //   accessor: (row: any, count: any) => {
    //     return <>{count + 1}</>
    //   },
      disableFilters: true,
      sticky: "left"
    },
    {
      Header: "Ticket Id",
    //   accessor: (a: any) => (a?.issue_id),
      sticky: "left"
    },
    {
      Header: "Order Id",
    //   accessor: (a: any) => (a?.order_id),
      sticky: "left"
    },
    {
      Header: "Network-Order-Id",
    //   accessor: (a: any) => (a?.order_id),
      sticky: "left"
    },
    {
      Header: "Buyer NP Name",
    //   accessor: (item: any) => (item?.buyer_NP_name ? item?.buyer_NP_name : null),
      sticky: "left"
    },
    {
      Header: "Seller NP Name",
      sticky: "left",
    //   accessor: (a: any) => (a ? "Redtape" : ""),
    },
    {
      Header: "On-Network Logistics NP Name",
      sticky: "left",
    //   accessor: () => ""
    },
    {
      Header: "Ticket Creation Time-Stamp",
    //   accessor: (a: any) => a?.createdat ? moment(a.createdat).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : '',
      sticky: "left",
    },
    {
      Header: "Issue Category (L1)",
    //   accessor: (a: any) => (a?.category),
      sticky: "left",
    },
    {
      Header: "Issue Sub-Category (L2)",
    //   accessor: (a: any) => subCategoryMap[a?.sub_category] || "",
      sticky: "left",
    },
    {
      Header: "Order Category",
    //   accessor: () => "Grocery",
      sticky: "left",
    },
    {
      Header: "Ticket Status",
    //   accessor: (a: any) => a?.status,
      sticky: "left",
    },
    {
      Header: "Ticket Closure TImestamp",
    //   accessor: (a: any) => a?.closed_time ? moment(a.closed_time).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : '',
      sticky: "left",
    },
    {
      Header: "Ticket Relay Timestamp to Seller NP",
    //   accessor: (a: any) => a?.createdat ? moment(a.createdat).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : '',
      sticky: "left",
    },
    {
      Header: "Ticket Relay Timestamp to Buyer NP",
      // accessor: (a => ((cache => a?.createdat ? (cache.get(a.createdat) || cache?.set(a.createdat, moment(a.createdat).subtract(Math.floor(Math.random() * 1000) + 3000, 'milliseconds')).get(a.createdat)).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : ''))(new Map())) as (a: any) => any,
    //   accessor: (a: any) => a?.createdat ? moment(a.createdat).subtract(Math.floor(Math.random() * 1000) + 3000, 'milliseconds').format('DD-MM-YYYY hh:mm:ss').toUpperCase() : '',
      // accessor: (a: any) => a?.createdat ? moment(a.createdat).subtract(3, 'seconds').format('DD-MM-YYYY hh:mm:ss').toUpperCase() : '',
      sticky: "left",
    },
    {
      Header: "Ticket Relay Timestamp to logistics NP",
    //   accessor: (item: any) => (item ? "" : null),
      sticky: "left",
    },
    {
      Header: "Ticket Last Update Timestamp",
    //   accessor: (a: any) => a?.updatedAt ? moment(a.updatedAt).format('DD-MM-YYYY hh:mm:ss').toUpperCase() : '',
      sticky: "left",
    },
  ];
  
  useEffect(() => {
    getRsfList();
  }, []);
  console.log("rsfList",rsfList)
  return (
    <>
      <ThemeMaker>
        <div className="row">
          <div className="col-md-12 pl-4 pr-4">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  RSF List
                </h6>
              </div>

              <div className="card-body">
              <div className="table-responsive table-bordered">
                  {
                    loading ? <Loader /> : (
                      <div className="table-responsive table-bordered ">
                        {!loading ? <Paginated data={filteredData} columns={COLUMNS} /> : <div className="d-flex justify-content-center align-items-center " style={{ minHeight: "60vh" }}><CircularProgress /></div>}
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
};

export default RsfList;
