import { useState, useEffect, CSSProperties } from "react";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { useCSVReader } from 'react-papaparse';
//import ReactS3Client from 'react-aws-s3-typescript';
import jwt_decode from "jwt-decode";
import config from "../../config/index";
import { basename } from "path";


const styles = {
    csvReader: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
    } as CSSProperties,
    browseFile: {

    } as CSSProperties,
    acceptedFile: {
        border: '1px solid #ccc',
        height: 45,
        lineHeight: 2.5,
        paddingLeft: 10,
        width: '50%',
    } as CSSProperties,
    remove: {
        borderRadius: 0,
        padding: '0 20px',
    } as CSSProperties,
    progressBarBackgroundColor: {
        backgroundColor: 'red',
    } as CSSProperties,
};
interface ICustomer {
    id: any;

}
const Addproduct = () => {
    const {baseurl} = config
    const { CSVReader } = useCSVReader();
    let params: ICustomer = useParams();
    // let history = useHistory();
    const { id } = params;
    const [pageNamem, setpageName] = useState(true);

    const [loading, setLoading] = useState(false);
    const [uploading, setUpLoading] = useState(false);

    const rawData = {
        title: '',
        description: '',
        status: 'draft',
        images: '',
        price: '',
        compareprice: '',
        netweight: '',
        sku: '',
        barcode: '',
        hscode: '',
        vendor: '',
        tags: '',
        productid: '',
        rank: '',
        category_id:''
    }
    const [productData, setProductData]: any = useState(rawData);
    const [csvData, setCsvData] = useState([]);

    const token: any = sessionStorage.getItem("isLogedIn");
    const decoded: any = jwt_decode(token);
    const { data } = decoded;

    const inputHandler = (e: any) => {
        setProductData({ ...productData, [e.target.name]: e.target.value });
    }

    const createProductHandler = async () => {
        setLoading(true);
        // console.log(productData);

        try {
            const { data } = await axios({
                url: `${baseurl}/product/create`,
                method: "POST",
                headers: {
                    Authorization: ` ${token}`
                },
                data: productData
            });
            if (data.message === "flag1") {
                toast.error("Product Id is already available !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (data.message === "flag2") {
                toast.success("Product created successfully !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setProductData(rawData);
            } else {
                toast.error("404 error !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("404 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }
    const updateProductHanlder = async () => {
        try {
            setUpLoading(true);
            await axios({
                url: `${baseurl}/product/update`,
                method: "PUT",
                headers: {
                    Authorization: ` ${token}`
                },
                data: productData
            });
            setUpLoading(false);
            localStorage.setItem('product', JSON.stringify(productData));
            toast.success("Product updated successfully !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } catch (error) {
            setUpLoading(false);
            toast.error("404 error ! please reach to support@ens.enterprises", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const importCsvHanlder = async () => {
        try {
            setLoading(true);
            const { data } = await axios({
                url: `${baseurl}/product/csv/import`,
                method: "POST",
                headers: {
                    Authorization: ` ${token}`
                },
                data: { data: csvData }
            });
            setLoading(false);
            if (data.message === "flag1") {
                toast.success("CSV imported successfully !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("error while importing !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            setLoading(false);
            toast.error("error while importing !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    const productImageHandler = async (e:any) => {
        // try {
        // const s3Config = {
        //     bucketName: 'ondctheushop',
        //     dirName: 'images',      /* Optional */
        //     region: 'ap-south-1',
        //     accessKeyId: 'AKIAR3ZQAKB3HURGWJHA',
        //     secretAccessKey: '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',
        // }
        //     const s3 = new ReactS3Client(s3Config);
        //     // const filename = 'test-1';
        //     // const res = await s3.uploadFile(e.target.files[0], filename);
        //     // console.log(res);
        // } catch (exception) {
        //     console.log(exception);
        // }
    }
    useEffect(() => {
        if (id) {
            setpageName(false);
            const user: any = localStorage.getItem("product");
            setProductData(JSON.parse(user))
        } else {
            setpageName(true)
        }
    }, []);
    
    return (
        <>
            <ThemeMaker>
                <div className="row">
                    <div className="col-md-12 pl-4 pr-4">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3 d-flex justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    {pageNamem ? "Create product" : "Update product"}
                                </h6>
                                {pageNamem ?
                                    <button className="btn btn-primary"
                                        data-toggle="modal" data-target="#exampleModal"
                                    > Import CSV </button> : null}
                            </div>
                            <div className="card-body">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Title</label>
                                            <input type="text" className="form-control" name="title" placeholder="Enter title"
                                                value={productData.title}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Product status</label>
                                            <select name="status"
                                                className="form-control"

                                                onChange={(e) => inputHandler(e)}
                                            >
                                                <option selected={productData.status === "draft" ? true : false} value="draft">Draft</option>
                                                <option selected={productData.status === "active" ? true : false} value="active">Active</option>

                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label >Description</label>
                                            <textarea className="form-control" name="description" placeholder="Enter title"
                                                value={productData.description}
                                                onChange={(e) => inputHandler(e)}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Price</label>
                                            <input type="number" className="form-control" name="price" placeholder="Enter price"
                                                value={productData.price}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label >Compare price</label>
                                            <input type="number" className="form-control" name="compareprice" placeholder="Enter compare price"
                                                value={productData.compareprice}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label >Net-Weight(gm)</label>
                                            <input type="number" className="form-control" name="netweight" placeholder="Enter compare price"
                                                value={productData.netweight}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >SKU</label>
                                            <input type="text" className="form-control" name="sku" placeholder="Enter sku"
                                                value={productData.sku}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Barcode</label>
                                            <input type="text" className="form-control" name="barcode" placeholder="Enter barcode"
                                                value={productData.barcode}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >HS code</label>
                                            <input type="text" className="form-control" name="hscode" placeholder="Enter HS code"
                                                value={productData.hscode}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Vendor</label>
                                            <input type="text" className="form-control" name="vendor" placeholder="Enter vendor"
                                                value={productData.vendor}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Tags</label>
                                            <input type="text" className="form-control" name="tags" placeholder="Enter tags"
                                                value={productData.tags}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Shopify Product Id</label>
                                            <input type="number" className="form-control" name="productid" placeholder="Enter shopify product Id"
                                                value={productData.productid}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Ranking</label>
                                            <input type="number" className="form-control" name="rank" placeholder="Ranking"
                                                value={productData.rank}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Category_id</label>
                                            <input type="text" className="form-control" name="category_id" placeholder="category_id"
                                                value={productData.category_id}
                                                onChange={(e) => inputHandler(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="formFileLg" className="form-label" >Images</label>
                                            <input type="file" className="form-control form-control-lg" id="formFileLg"
                                            // onChange={(e)=> productImageHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">

                                        {
                                            pageNamem ? (
                                                <button type="submit" className="btn btn-primary"

                                                    onClick={createProductHandler}>
                                                    {
                                                        loading ? 'Loading...' : "Create"
                                                    }
                                                </button>
                                            ) : (
                                                <button type="submit" className="btn btn-success"
                                                    onClick={updateProductHanlder}
                                                >
                                                    {
                                                        uploading ? 'Loading...' : "Update"
                                                    }
                                                </button>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="exampleModal" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center"></h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <CSVReader
                                        onUploadAccepted={(results: any) => {
                                            results.data.splice(0, 1);
                                            setCsvData(results.data);
                                        }}
                                    >
                                        {({
                                            getRootProps,
                                            acceptedFile,
                                            ProgressBar,
                                            getRemoveFileProps,
                                        }: any) => (
                                            <>
                                                <div style={styles.csvReader}>
                                                    <button type='button' className="btn btn-primary" {...getRootProps()} style={styles.browseFile}>
                                                        Browse file
                                                    </button>
                                                    <div style={styles.acceptedFile}>
                                                        {acceptedFile && acceptedFile.name}
                                                    </div>
                                                    <button className="btn btn-danger"  {...getRemoveFileProps()} style={styles.remove}>
                                                        Remove
                                                    </button>
                                                </div>
                                                <ProgressBar style={styles.progressBarBackgroundColor} />
                                            </>
                                        )}
                                    </CSVReader>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success"
                                    onClick={importCsvHanlder}
                                >
                                    {
                                        loading ? 'Loading...' : "Import"
                                    }

                                </button>

                                <button type="button" id="closebtn" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}

export default Addproduct;