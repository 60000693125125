import { useState, useEffect, useRef, useCallback } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { toast } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
// import "../orderlist/orderlistPopus.css";
import Loader from "../../components/tabelloader";
import jwt_decode from "jwt-decode";
import { Paginated } from "../../components/Paginated";
import ThemeMaker from "../../components/thememaker";
import React from "react";
import config from "../../config/index";
// import OrderList from "../orderlist";
var $ = require("jquery");

const CancelReturn = () => {
  const {baseurl} = config
  const [popup, setPopup] = useState(false);
  const [orderpopup, setOrderpopup] = useState([]);
  const token: any = sessionStorage.getItem("isLogedIn");
  const decoded: any = jwt_decode(token);
  const { user, product, order, order_manage } = decoded.data.permission;
  const history = useHistory();
  const [orderList, setOrderList] = useState<Orders[]>([]);
  const [updateList, setupdateList] = useState([]);
  const [value, setValue] = useState("In-progress");
  const [status, setstatus]: any = useState([]);
  const [returnprovider, setreturnprovider] = useState();
  const [returnitemcount, setreturnitemcount] = useState();
  const [returncontext, setreturncontext] = useState();
  const [loading, setloading] = useState(true);
  const [listloading, setlistloading] = useState(true);
  const closeModel: any = useRef();
  const [error, setError] = useState("");
  const [_id, setId] = useState("");
  const [itenList, setItemList] = useState([]);
  const [searchList, setSearchList] = useState([]);

  // const changeStatus = async (orderId: any, itemId: any) => {
  //   console.log("====================================");
  //   console.log("orderId", orderId);
  //   console.log("itemId", itemId);

  //   const returnContextData = {
  //     context: returncontext,
  //     message: {
  //       update_target: "item",
  //       order: {
  //         id: orderId,
  //         state: status,
  //         provider: {
  //           id: returnprovider,
  //         },
  //         items: [
  //           {
  //             id: itemId,
  //             quantity: {
  //               count: returnitemcount,
  //             },
  //             tags: {
  //               status: status,
  //             },
  //           },
  //         ],
  //       },
  //     },
  //   };
  //   console.log("returnContextData 67", returnContextData);
  //   console.log("====================================");

  //   try {
  //     const { data } = await axios({
  //       url: `/orders/itemstatus`,
  //       method: "PUT",
  //       headers: {
  //         Authorization: ` ${token}`
  //     },
  //       data: {
  //         order_id: orderId,
  //         item_id: itemId,
  //         orderStatus: status,
  //       },
  //     });
  //     console.log("sas => ", data);
  //     if (data.status === 200) {
  //       // alert("status updating");
  //       // toast.success("Status updated successfully")
  //       toast.success(`${status} successfully`);
  //     } else {
  //       alert("404 not found !");
  //     }
  //     setloading(false);
  //     setTimeout(() => {
  //       $("#dataTable").DataTable();
  //     }, 1000);
  //     // console.log("data>>>>>>", data);
  //   } catch (error) {
  //     setloading(false);
  //   }

  //   try {
  //     const { data } = await axios({
  //       url: `/v1/Return_Approved`,
  //       method: "PUT",
  //       headers: {
  //         Authorization: ` ${token}`
  //     },
  //       data: returnContextData,
  //     });
  //     console.log("sas => ", data);
  //     if (data.status === 200) {
  //       alert("status updating on buyyer");
  //     } else {
  //       alert("404 not found ! buyerer");
  //     }
  //     setloading(false);
  //     setTimeout(() => {
  //       $("#dataTable").DataTable();
  //     }, 1000);
  //     // console.log("data>>>>>>", data);
  //   } catch (error) {
  //     setloading(false);
  //   }
  // };

  const changeStatus = async (orderId: any, status: any) => {
    try {
      const { data } = await axios({
        url: `${baseurl}/orders/itemstatus`,
        method: "PUT",
        headers: {
          Authorization: ` ${token}`
        },
        data: {
          order_id: orderId,
          orderStatus: status,
        },
      });
      if (data.status === 200) {
        toast.success(`${status} successfully`);
        await changeUpdate_Unsolicated(orderId, `Return_${orderId}`);
      } else {
        alert("404 not found !");
      }
      setloading(false);
      // console.log("data>>>>>>", data);
    } catch (error) {
      setloading(false);
    }
  };

  const changeUpdate_Unsolicated = async (order_id: any, fulfillment_cancel_id: any) => {
    try {
      const { data } = await axios({
        url: `${baseurl}/unsolicated_return`,
        // old
        // url: `/Unsolicated_Update`,
        method: "POST",
        headers: {
          Authorization: ` ${token}`
        },
        data: {
          orderid: order_id,
          fulfillment_cancel_id,
          // status: status
        },
      });
      if (data.status === 200) {
        toast.success("Status updated successfully by seller app");
      } else {
        toast.error(`Error: ${data.message}`);
      }
    }
    catch (error: any) {
      console.log("error", error);
      toast.error(`Error : ${error.message}`);
    }
  };

  const get_item_details = async (order_id: any) => {
    // debugger
    // console.log("order_id", order_id)
    try {
      const { data } = await axios({
        url: `${baseurl}/orders/getReturnItemByOrder`,
        method: "POST",
        headers: {
          Authorization: token
        },
        data: {
          ondcorderid: order_id,
        },
      });
      setItemList(data?.data)
    } catch (error: any) {
      toast.error(`Error : ${error.message}`);
    }
  };

  const getOrderList = async () => {
    try {
      const {
        data: { data },
      } = await axios({
        url: `${baseurl}/orders/npdata`,
        method: "GET",
        headers: {
          Authorization: ` ${token}`
        },
      });
      setOrderList(data.reverse());
      setloading(false);
      //console.log("Product Data>>", data);
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 1000);
    } catch (error) {
      setloading(false);
      setError("error");
    }
  };


  const handleChange = (event: any) => {
    setValue(event.target.value);
  };
  // const StatusChange = (event: any) => {
  //   setstatus(event.target.value);
  // };

  // const changeReturnStatus = (item: any, selectedStatus: any) => {
  //   console.log(
  //     "changeReturnStatus item: ",
  //     item,
  //     "changeReturnStatus: selectedStatus",
  //     selectedStatus
  //   );
  //   item.status = selectedStatus;
  //   setstatus(selectedStatus);
  //   setOrderpopup([...orderpopup]);
  // };
  const changeReturnStatus = (item: any, selectedStatus: any) => {
    // console.log("selectedStatus", selectedStatus)
    return { status: selectedStatus };
  };

  // const changeReturnStatus = (item: any, selectedStatus: any) => {
  //   // item.status = selectedStatus;
  //   console.log("item", item, "Selected Status", selectedStatus)
  //   setstatus(selectedStatus);
  //   setOrderpopup([...orderpopup]);
  // };

  // const changeStatusseller = async (OrderId: any, OrderContext: any) => {
  //   console.log("orderId", OrderId);
  //   console.log("OrderContext", OrderContext);
  //   // context.message_id = uuidv4();
  //   const contextdata = {
  //     OrderContext,
  //     message: {
  //       order_id: OrderId,
  //     },
  //   };
  //   console.log("contaxtdata", contextdata);
  //   try {
  //     const { data } = await axios({
  //       url: `https://ondc.hulsecure.in/preprod/v1/Return_Approved`,
  //       method: "PUT",
  //       data: contextdata,
  //     });
  //     console.log("sas => ", data);
  //     toast.success("Status updated by seller app");
  //     console.log("data>>>>>>", data);
  //   } catch (error: any) {
  //     console.log("error", error);
  //     toast.error(`Error : ${error.message}`);
  //   }
  // };

  // const CancelItem = async (OrderId: any, item_id: any) => {
  //   console.log("orderId", OrderId);
  //   console.log("item_id", item_id);
  //   // context.message_id = uuidv4();
  //   const contextdata = {
  //     order_id: OrderId,
  //     item_id,
  //     status:"Cancelled"
  //   };
  //   console.log("contaxtdata", contextdata);
  //   try {
  //     const { data } = await axios({
  //       // url: `https://ondc.hulsecure.in/preprod/v1/cancel-item`,
  //       url: `https://localhost:8092/preprod/v1/cancel-item`,
  //       method: "PUT",
  //       data: contextdata,
  //     });
  //     console.log("sas => ", data);
  //     toast.success("Status updated by seller app");
  //     console.log("data>>>>>>", data);
  //   } catch (error: any) {
  //     console.log("error", error);
  //     toast.error(`Error : ${error.message}`);
  //   }
  // };

  interface Orders {
    index: number;
    ondcorderid: number;
    status: string;
    // response: any;
  }

  // const cancelOrders:Orders[] = orderList;

  const cancelOrders = orderList.filter((order) => order.status == `${value}`);
  // const cancelOrder = (orderList as Orders[]).filter(order => order.status == 'cancelled');

  // console.log("cancelOrder>153", cancelOrders);
  // console.log("orderList>154", orderList);

  // const getUpdates = async () => {
  //   // alert();
  //   try {
  //     const { data } = await axios({
  //       url: `/orders/on_update`,
  //       method: "GET",
  //     });
  //     // console.log("UpdateData=> 217", data);
  //     if (data.status === 200) {
  //       // alert("status updating");
  //       // console.log("UpdateData=> 220", data.data);
  //     } else {
  //       console.log("error222", data);
  //     }
  //     setloading(false);
  //     setTimeout(() => {
  //       $("#dataTable").DataTable();
  //     }, 1000);
  //     // console.log("UpdateData=> 227", data);
  //   } catch (error) {
  //     setloading(false);
  //   }
  // };

  // useEffect(() => {
  //   getUpdates();
  // }, []);

  const getUpdateList = async () => {
    setlistloading(true);
    try {
      const {
        data: { data },
      } = await axios({
        url: `${baseurl}/orders/getupdatelogs`,
        method: "GET",
        headers: {
          Authorization: ` ${token}`
        },
      });
      setupdateList(data.reverse());
      setSearchList(data.reverse());
      setlistloading(false);
    } catch (error) {
      setlistloading(false);
      setError("error");
    }
  };

  // FIlter Search
  const filterBySearch = (event: any) => {
    const querySearch = event.target.value;
    let SearchFiltere = updateList?.filter((item: any) => {
      return (
        item?._id?.ondcorderid.toLowerCase().includes(querySearch?.toLowerCase()) ||
        item?._id.status?.toLowerCase().includes(querySearch?.toLowerCase())
      );
    });
    setSearchList(SearchFiltere);
  };

  const handledata = async (
    orderID: any,
    itemID: any,
    context: any,
    itemCount: any,
    provider: any
  ) => {
    setPopup(!popup);
    setreturncontext(context);
    setreturnitemcount(itemCount);
    setreturnprovider(provider);

    try {
      const { data } = await axios({
        url: `${baseurl}/orders/itemupdatedetails`,
        method: "POST",
        headers: {
          Authorization: ` ${token}`
        },
        data: {
          order_id: orderID,
          item_id: itemID,
        },
      });
      console.log("sas => ", data);
      if (data.status === 200) {
        setOrderpopup(data.data.reverse());
      } else {
        alert("404 not found !");
      }
      setloading(false);
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 1000);
      // console.log("data>>>>>>", data);
    } catch (error) {
      setloading(false);
    }
    const closePopup = () => {
      setPopup(false);
    };
  };

  const ActionCell = React.memo(({ row, changeReturnStatus }: any) => {
    const initialStatus = row.original?._id?.status || "";
    const [status, setStatus] = useState(initialStatus);
    const [loading, Setloading] = useState(false);

    const handleStatusChange = useCallback((e: any) => {
    debugger
      Setloading(true)
      const selectedStatus = e.target.value;
      setStatus(selectedStatus);
      changeReturnStatus(row.original?._id?.status, selectedStatus);
    }, [row.original, changeReturnStatus,]);
    
    return (
      <>
        <select className="btn  btn-sm border-bottom-secondary" value={status} onChange={handleStatusChange}>
          <option value="" selected>Select Status</option>
          <option value="Return_Initiated">Return_Initiated</option>
          <option value="Return_Approved">Return_Approved</option>
          <option value="Return_Rejected">Return_Rejected</option>
          <option value="Return_Picked">Return_Picked</option>
          <option value="Return_Delivered">Return_Delivered</option>
          <option value="Liquidated">Liquidated</option>
          <option value="Cancelled">Cancelled</option>
        </select>
        {loading ? (
          <small className="d-block text-capitalize">You selected : <span className="text-danger">{status}</span></small>
        ) : null}
        <button className="btn btn-primary d-flex mx-auto my-3" type="submit" onClick={() => changeStatus(row.original, status)}>Update</button>
      </>
    );
  });

  const COLUMNS = [
    {
      Header: "Sn No",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>
      },
      disableFilters: true,
      sticky: "left"
    },
    {
      Header: "Order Id	",
      accessor: (a: any) => (a?._id.ondcorderid ? a?._id.ondcorderid : ""),
      sticky: "left"
    },
    {
      Header: "Updated AT",
      accessor: (a: any) => (a?._id.updatedAt ? a?._id.updatedAt : ""),
      sticky: "left"
    },
    {
      Header: "Status",
      accessor: (a: any) => (a?._id.status ? a?._id.status : ""),
      sticky: "left"
    },
    // {
    //   Header: "Action",
    //   accessor: (a: any) => a?.order_id,
    //   sticky: "left",
    //   Cell: ({ row }: any) => (
    //     <><div>
    //       <select className="btn btn-secondary" value={status}
    //         onChange={(e) => changeReturnStatus(row.original, e.target.value)}>
    //         <option value="Return_Initiated">Return_Initiated</option>
    //         <option value="Return_Approved">Return_Approved</option>
    //         <option value="Return_Rejected">Return_Rejected</option>
    //         <option value="Return_Picked">Return_Picked</option>
    //         <option value="Return_Delivered">Return_Delivered</option>
    //         <option value="Liquidated">Liquidated</option>
    //         <option value="Cancelled">Cancelled</option>
    //       </select>
    //       <p className="d-block">{`You selected : ${status}`}</p>
    //     </div><button className="btn btn-primary d-flex mx-auto my-3"
    //       type="submit"
    //       onClick={() => changeStatus(row.original)}>
    //         Updatess
    //       </button></>
    //   ),
    // },
    {
      Header: "Action",
      accessor: (a: any) => a?._id.status,
      sticky: "left",
      Cell: ({ row }: any) => (
        <ActionCell
          row={row}
          changeReturnStatus={changeReturnStatus}
        />
      ),
    },
    // {
    //   Header: "Action",
    //   accessor: (a: any) => a?.order_id,
    //   sticky: "left",
    //   Cell: ({ row }: any) => {
    //     const [status, setStatus]:any = useState(""); 
    //     // Function to handle status change
    //     const handleChangeStatus = (selectedStatus: string) => {
    //       setStatus(selectedStatus);
    //     };

    //     const handleUpdateStatus = (order: any) => {

    //       console.log("Updating status for order:", order, "to:", status);
    //     };

    //     return (
    //       <>
    //         <div>
    //           <select
    //             className="btn btn-secondary"
    //             value={status}
    //             onChange={(e) => handleChangeStatus(e.target.value)}
    //           >
    //             <option disabled selected>Change Status</option>
    //             <option value="Return_Initiated">Return_Initiated</option>
    //             <option value="Return_Approved">Return_Approved</option>
    //             <option value="Return_Rejected">Return_Rejected</option>
    //             <option value="Return_Picked">Return_Picked</option>
    //             <option value="Return_Delivered">Return_Delivered</option>
    //             <option value="Liquidated">Liquidated</option>
    //             {/* <option value="Cancelled">Cancelled</option> */}
    //           </select>
    //           <p className="d-block">{`You selected : ${status}`}</p>
    //         </div>
    //         <button
    //           className="btn btn-primary d-flex mx-auto my-3"
    //           type="submit"
    //           onClick={() => handleUpdateStatus(row.original)}
    //         >
    //           Status Update
    //         </button>
    //       </>
    //     );
    //   },
    // },

    {
      Header: "Item - Details",
      accessor: (a: any) => (a ? a?.order_id : ""),
      sticky: "left",
      Cell: ({ row }: any) => (
        <><button className="btn btn badge-success" data-toggle="modal"
          data-target="#myModal"
          onClick={() => {
            get_item_details(row.original?._id.ondcorderid);
          }}
        >
          View
        </button></>
      ),
    }
  ];

  useEffect(() => {
    getOrderList();
    getUpdateList();
  }, []);

  return (
    <>
      <ThemeMaker>
        {/* <div>
          <div className="modal fade" id="myModal" role="dialog">
            <div className="modal-dialog ">
              <div className="modal-content container">
                <div className="modal-header">
                  <h2>Orders Details</h2>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>

                <div className="modal-body">
                  <table
                    className="table table-bordered"
                    id="dataTable"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>Sn</th>
                        <th>Shopify OrderId</th>
                        <th>ONDC OrderId</th>
                        <th>Item Id</th>
                        <th>
                          Cancellation reason /retrun reason(Network
                          description)
                        </th>
                        <th>Cancelled At Date & time</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tfoot></tfoot>
                    <tbody>
                      {orderpopup.map((item: any, index) => (
                        <tr key={item._id}>
                          <td>{index + 1}</td>

                          <td>{item?.shopifyorderid}</td>
                          <td>{item?.ondcorderid}</td>
                          <td>{item?.itemid}</td>
                          <td>{item?.cancellation_reason}</td>
                          <td>{item?.cancel_date}</td>
                          <td>{item?.status}</td>
                          <td>
                            <div>
                              <select
                                className="btn btn-secondary"
                                // value={status}
                                // onChange={StatusChange}
                                value={item.status}
                                onChange={(e) =>
                                  changeReturnStatus(item, e.target.value)
                                }
                              >
                                <option value="Return_Initiated">
                                  Return_Initiated
                                </option>
                                <option value="Return_Approved">
                                  Return_Approved
                                </option>
                                <option value="Return_Rejected">
                                  Return_Rejected
                                </option>
                                <option value="Return_Picked">
                                  Return_Picked
                                </option>
                                <option value="Return_Delivered">
                                  Return_Delivered
                                </option>
                                <option value="Liquidated">Liquidated</option>
                                <option value="Cancelled">Cancelled</option>
                              </select>
                              <p className="d-none">{`You selected : ${status}`}</p>
                            </div>

                            <button
                              className="btn btn-primary d-flex mx-auto my-3"
                              type="submit"
                              // onClick={() =>
                              //   changeStatus(item?.ondcorderid, item.itemid)
                              // }
                            >
                              Update
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="row d-none">
          <div className="col-md-12 pl-4 pr-4">
            <div className="card shadow mb-4">
              <div className="card-header py-3"></div>
              <div className="d-flex justify-content-between align-items-center m-2">
                <select
                  className="btn btn-outline-primary"
                  value={value}
                  onChange={handleChange}
                >
                  <option value="In-progress">In-progress</option>
                  <option value="false">False</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="Delivered">Delivered</option>
                  <option value="Completed">Completed</option>
                  <option value="Created">Created</option>
                </select>
                <p>{`You selected : ${value}`}</p>
              </div>

              <table
                className="table table-bordered"
                id="dataTable"
                width="100%"
              >
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>ID</th>
                    <th>Status</th>
                    {/* <th>Delivered Date</th> */}
                  </tr>
                </thead>
                <tbody>
                  {cancelOrders &&
                    cancelOrders.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.ondcorderid}</td>
                          <td> {item.status}</td>
                          {/* <td> {item.response.message.order.fulfillment[0].end.timestamp}</td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 pl-4 pr-4">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <div className="row">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Cancel & Return List
                  </h6>
                </div>
                <div className="search mt-1">
                  <div className="row m-0 d-flex justify-content-center">
                    <div className="d-flex w-100">
                      <div className="">
                        <label htmlFor="exampleInputEmail1">Search by Order ID | Status</label>
                        <input type="search" name="order_id" placeholder="Search" className="mr-2 form-control"
                          onChange={(event) => filterBySearch(event)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="table-responsive table-bordered">
                  {listloading ? (
                    <Loader />
                  ) : (
                    <Paginated data={searchList} columns={COLUMNS} />
                    // <table
                    //   className="table table-bordered"
                    //   id="dataTable"
                    //   width="100%"
                    // >
                    //   <thead>
                    //     <tr>
                    //       <th>Sn</th>
                    //       <th>order id</th>
                    //       <th>Delivered Date</th>
                    //       <th>item id</th>
                    //       {/* <th>button</th> */}
                    //     </tr>
                    //   </thead>
                    //   <tfoot></tfoot>
                    //   <tbody>
                    //     {updateList.map((item: any, index) => {
                    //       return (
                    //         <tr key={item._id}>
                    //           <td>{index + 1}</td>

                    //           <td>{item?.body.message.order.id}</td>
                    //           <td>{item?.updatedAt}</td>
                    //           <td>
                    //             <table className="w-100">
                    //               <tr>
                    //                 <th>SN</th>
                    //                 <th>Item ID</th>
                    //                 <th>Reason</th>
                    //                 <th>Update Type</th>
                    //                 <th>Quantity Count</th>
                    //                 <th>Details</th>
                    //               </tr>
                    //               {item?.body?.message?.order?.items &&
                    //               item.body.message.order.items.length > 0
                    //                 ? item.body.message.order.items.map(
                    //                     (value: any, i: number) => (
                    //                       <tr key={i} className="">
                    //                         <td>{i + 1}: </td>
                    //                         <td>{value?.id}</td>
                    //                         <td>
                    //                           {value?.tags?.reason_code ===
                    //                           "001" ? (
                    //                             <span>
                    //                               Buyer does not want product
                    //                               any more
                    //                             </span>
                    //                           ) : value?.tags?.reason_code ===
                    //                             "002" ? (
                    //                             <span>
                    //                               Product available at lower
                    //                               than order price
                    //                             </span>
                    //                           ) : value?.tags?.reason_code ===
                    //                             "003" ? (
                    //                             <span>
                    //                               Product available at lower
                    //                               than order price
                    //                             </span>
                    //                           ) : value?.tags?.reason_code ===
                    //                             "004" ? (
                    //                             <span>
                    //                               Product is of incorrect
                    //                               quantity or size
                    //                             </span>
                    //                           ) : (
                    //                             <span>Default message</span>
                    //                           )}
                    //                         </td>
                    //                         <td>{value?.tags?.update_type}</td>
                    //                         <td>{value?.quantity?.count}</td>
                    //                         <td>
                    //                           <button
                    //                             className="btn btn-info btn-lg"
                    //                             data-toggle="modal"
                    //                             data-target="#myModal"
                    //                             onClick={() =>
                    //                               handledata(
                    //                                 item?.body.message.order.id,
                    //                                 value?.id,
                    //                                 item?.body.context,
                    //                                 value?.quantity?.count,
                    //                                 item?.body?.message?.order
                    //                                   ?.provider?.id
                    //                               )
                    //                             }
                    //                           >
                    //                             View
                    //                           </button>
                    //                           {/* <button
                    //                             className="btn btn-outline-info my-2"
                    //                             onClick={() =>
                    //                               changeStatusseller(
                    //                                 item?.body.message,
                    //                                 item?.body.context
                    //                               )
                    //                             }
                    //                           >
                    //                             Update
                    //                           </button> */}
                    //                         </td>
                    //                       </tr>
                    //                     )
                    //                   )
                    //                 : null}
                    //             </table>
                    //           </td>
                    //         </tr>
                    //       );
                    //       // }
                    //     })}
                    //   </tbody>
                    // </table>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="myModal" role="dialog">
            <div className="modal-dialog ">
              <div className="modal-content container">
                <div className="modal-header">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Orders Details
                  </h6>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <table className="table table-bordered" id="dataTable" width="100%">
                    <thead>
                      <tr>
                        <th>SN</th>
                        <th>Item ID</th>
                        <th>Reason</th>
                        <th>Update Type</th>
                        {/* <th>Quantity Count</th> */}
                      </tr>
                    </thead>
                    <tfoot></tfoot>
                    <tbody>
                      {itenList.map((item: any, index) => (
                        <tr key={item._id}>
                          <td>{index + 1}: </td>
                          <td>{item?.itemid}</td>
                          <td>
                            {item?.cancellation_reason_id ===
                              "001" ? (
                              <span>
                                Buyer does not want product
                                any more
                              </span>
                            ) : item?.cancellation_reason_id ===
                              "002" ? (
                              <span>
                                Product available at lower
                                than order price
                              </span>
                            ) : item?.cancellation_reason_id ===
                              "003" ? (
                              <span>
                                Product available at lower
                                than order price
                              </span>
                            ) : item?.cancellation_reason_id ===
                              "004" ? (
                              <span>
                                Product is of incorrect
                                quantity or size
                              </span>
                            ) : (
                              <span> </span>
                            )}
                          </td>
                          <td>{item?.status}</td>
                          {/* <td>
                            <div>
                              <select
                                className="btn btn-secondary"
                                // value={status}
                                // onChange={StatusChange}
                                value={item.status}
                                onChange={(e) =>
                                  changeReturnStatus(item, e.target.value)
                                }
                              >
                                <option value="Return_Initiated">
                                  Return_Initiated
                                </option>
                                <option value="Return_Approved">
                                  Return_Approved
                                </option>
                                <option value="Return_Rejected">
                                  Return_Rejected
                                </option>
                                <option value="Return_Picked">
                                  Return_Picked
                                </option>
                                <option value="Return_Delivered">
                                  Return_Delivered
                                </option>
                                <option value="Liquidated">Liquidated</option>
                                <option value="Cancelled">Cancelled</option>
                              </select>
                              <p className="d-none">{`You selected : ${status}`}</p>
                            </div>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
};

export default CancelReturn;
