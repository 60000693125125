import { useState, useEffect, useRef } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Loader from "../../components/tabelloader";
import jwt_decode from "jwt-decode";
import config from "../../config/index";
import ThemeMaker from "../../components/thememaker";
import { basename } from "path";
var $ = require("jquery");

const myStatus:any = {
  2:"Open",
  3:"Pending",
  4:"Resolved",
  5:"Closed"
}
const SupportList = () => {
  const {baseurl} = config
  const token: any = sessionStorage.getItem("isLogedIn");
  const decoded: any = jwt_decode(token);
  //console.log(decoded.data.permission.user);
  const { user, product, order, support } = decoded.data.permission;

  const history = useHistory();
  const [supportList, setSupportList] = useState([]);

  const [loading, setloading] = useState(true);
  const closeModel: any = useRef();

  const [error, setError] = useState("");
  const [_id, setId] = useState("");

  const getSupportList = async () => {
    try {
      const {
        data: { data },
      } = await axios({
        url: `${baseurl}/orders/getsupport`,
        method: "GET",
         headers: {
            Authorization: ` ${token}`
        },
      });
      setSupportList(data);
      setloading(false);
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 1000);
    } catch (error) {
      setloading(false);
      setError("error");
    }
  };

  // const ndate = new Date(orderList.createdAt);
  // const checkdate = ndate.toLocaleDateString()
  const redirectToUserPage = (_id: any) => {
    const product = supportList.find((item: any) => item._id === _id);
    localStorage.setItem("product", JSON.stringify(product));
    history.push(`/product/${_id}`);
  };
  useEffect(() => {
    getSupportList();
  }, []);
  return (
    <>
      <ThemeMaker>
        <div className="row">
          <div className="col-md-12 pl-4 pr-4">
            <div className="card shadow mb-4">
              <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">
                  Support List
                </h6>
              </div>

              <div className="card-body">
                <div className="table-responsive">
                  {loading ? (
                    <Loader />
                  ) : (
                    <table
                      className="table table-bordered"
                      id="dataTable"
                      width="100%"
                    >
                      <thead>
                        <tr>
                          <th>Sn</th>
                          <th>Ticket id</th>
                          <th>Network-Order-ID</th>
                          <th>Status</th>
                          <th>Created_at</th>
                          <th>Resolved_at</th>
                          
                          <th>Buyer App</th>
                          <th>Seller NP</th>
                          <th>Seller</th>
                          <th>Issue Description</th>
                          <th>Resolved Date</th>
                       
                          <th>Resolved By</th>
                          <th>Estimate Time of Ticket Closer </th>


                          

                        </tr>
                      </thead>
                      <tfoot></tfoot>
                      <tbody>
                        {supportList?.map((item: any, index) => {
                     
                          return (
                            <tr key={item._id}>
                              <td>{index + 1}</td>
                              <td>{item.id}</td>
                              <td>{item.subject}</td>
                              <td>
                                { myStatus[item?.status] }
                              </td>
                              <td>{item.created_at}</td>
                              <td>{item.stats?.resolved_at}</td>
                              <td>Paytm</td>
                              <td>customercare@theushop.in</td>
                              <td>Theushop</td>
                              <td>Testing..</td>
                              <td>{item.stats?.resolved_at}</td>
                              <td>{item.responder_id==null?"theUshop":""}</td>
                              <td>{item.due_by}</td>

                          

                            </tr>
                          )
                        } )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeMaker>
    </>
  );
};

export default SupportList;
