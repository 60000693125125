/* eslint-disable jsx-a11y/anchor-is-valid */
import jwt_decode from "jwt-decode";
import LogoComponent from "../../LogoUshop";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const token: any = sessionStorage.getItem("isLogedIn");
  const decoded: any = jwt_decode(token);
  const { data } = decoded;
  const { isAdmin, permission: { product, user, order, order_manage, igm }, } = data;

  // console.log("order_manage 14",order_manage.read);
  // console.log("product 14", product);
  // console.log("isAdmin 14",isAdmin);
  // console.log('data 16==>',data);

  return (
    <>
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        <Link to="/">
          <a href="replace" className="sidebar-brand bg-white d-flex justify-content-between align-items-center">
            {/* <div className="sidebar-brand-icon rotate-n-15"></div> */}
            <div className="sidebar-brand-text" style={{color:"#0d009d",fontSize:"23px",margin:"auto"}}>ONDC </div>
            <LogoComponent width={150} />
          </a>
        </Link>
        <hr className="sidebar-divider my-0" />

        <li className="nav-item active">
          <Link to="/">
            <a href="replace" className="nav-link">
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </a>
          </Link>
        </li>

        <hr className="sidebar-divider" />

        <div className="sidebar-heading">Interface</div>
        {/* { isAdmin === "true" ? ( */}
        <li className="nav-item">
          <Link to="#">
            <a
              className="nav-link collapsed"
              href="replace"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              <i className="fas fa-fw fa-cog"></i>
              <span>Products</span>
            </a>
          </Link>
          <div
            id="collapseTwo"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              {isAdmin === "true" ? (
                <>
                  <Link to="/addproduct">
                    <a href="replace" className="collapse-item">
                      Add product
                    </a>
                  </Link>
                  <Link to="/productlist">
                    <a href="replace" className="collapse-item">
                      Product List
                    </a>
                  </Link>
                </>
              ) : (product?.read === true || product?.write === true) ? (
                // {
                (product?.read === true && product?.write === true) ? (
                  <>
                    <Link to="/addproduct">
                      <a href="replace" className="collapse-item">
                        Add Product
                      </a>
                    </Link>
                    <Link to="/productlist">
                      <a href="replace" className="collapse-item">
                        Product List
                      </a>
                    </Link>
                  </>
                ) : (product?.write === true) ? (
                  <>
                    <Link to="/addproduct">
                      <a href="replace" className="collapse-item">
                        Add Product
                      </a>
                    </Link>
                  </>
                ) : (product?.read === true) ? (
                  <>
                    <Link to="/productlist">
                      <a href="replace" className="collapse-item">
                        Product List
                      </a>
                    </Link>
                  </>
                ) : null
              ) : null
              }
            </div>
          </div>
        </li>
        {/* {
          isAdmin === "true" ? (
            <>
              <li className="nav-item">
                <Link to="#">
                  <a
                    className="nav-link collapsed"
                    href="replace"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    <i className="fas fa-fw fa-cog"></i>
                    <span>Products</span>
                  </a>
                </Link>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionSidebar"
                >
                  <div className="bg-white py-2 collapse-inner rounded">
                    {isAdmin === "true" ? (
                      <Link to="/addproduct">
                        <a href="replace" className="collapse-item">
                          Add product
                        </a>
                      </Link>
                    ) : product.write ? (
                      <Link to="/addproduct">
                        <a href="replace" className="collapse-item">
                          Add product
                        </a>
                      </Link>
                    ) : null}
                    {isAdmin === "true" ? (
                      <Link to="/productlist">
                        <a href="replace" className="collapse-item">
                          Product List
                        </a>
                      </Link>
                    ) : product.read ? (
                      <Link to="/productlist">
                        <a href="replace" className="collapse-item">
                          Product List
                        </a>
                      </Link>
                    ) : null}
                  </div>
                </div>
              </li>
            </>
          ) :null
        } */}
        {/* // )
        // } */}
        {/* {
    isAdmin === "true" ? (
      <Link to="/addproduct">
      <a href="replace" className="collapse-item">
        Add product
      </a>
    </Link>
      ):product.read ?(
        <Link to="/productlist">
        <a href="replace" className="collapse-item">
          Product List
        </a>
      </Link>
      ): product.write ? (
        <Link to="/addproduct">
          <a href="replace" className="collapse-item">
            Add product
          </a>
        </Link>
      ):null
    } */}

        <hr className="sidebar-divider" />

        <div className="sidebar-heading">Order</div>

        {isAdmin === "true" ? (
          <>
            <li className="nav-item">
              <Link to="#">
                <a
                  className="nav-link collapsed"
                  href="replace"
                  data-toggle="collapse"
                  data-target="#collapseOrder"
                  aria-expanded="true"
                  aria-controls="collapseOrder"
                >
                  <i className="fas fa-fw fa-cog"></i>
                  <span>Orders</span>
                </a>
              </Link>
              <div
                id="collapseOrder"
                className="collapse"
                aria-labelledby="headingOrder"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {order?.read === true ? (
                    <>
                      <Link to="/orderlist">
                        <a href="replace" className="collapse-item">
                          Orders List
                        </a>
                      </Link>
                      {/* <Link to="/rsflist">
                        <a href="replace" className="collapse-item">
                          RSF
                        </a>
                      </Link> */}
                    </>
                  ) : null}
                </div>
              </div>
            </li>
          </>
        ) : order?.read ? (
          <>
            <li className="nav-item">
              <Link to="#">
                <a
                  className="nav-link collapsed"
                  href="replace"
                  data-toggle="collapse"
                  data-target="#collapseOrder"
                  aria-expanded="true"
                  aria-controls="collapseOrder"
                >
                  <i className="fas fa-fw fa-cog"></i>
                  <span>Orders</span>
                </a>
              </Link>
              <div
                id="collapseOrder"
                className="collapse"
                aria-labelledby="headingOrder"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {order?.read === true ? (
                    <>
                      <Link to="/orderlist">
                        <a href="replace" className="collapse-item">
                          Orders List
                        </a>
                      </Link>
                      {/* <Link to="/rsflist">
                        <a href="replace" className="collapse-item">
                          RSF
                        </a>
                      </Link> */}
                    </>
                  ) : null}
                </div>
              </div>
            </li>
          </>
        ) : null}
        <hr className="sidebar-divider" />

        <div className="sidebar-heading">Order Management</div>

        {isAdmin === "true" ? (
          <>
            <li className="nav-item">
              <Link to="#">
                <a
                  className="nav-link collapsed"
                  href="replace"
                  data-toggle="collapse"
                  data-target="#collapseOrderManage"
                  aria-expanded="true"
                  aria-controls="collapseOrderManage"
                >
                  <i className="fas fa-fw fa-cog"></i>
                  <span>Manage Orders</span>
                </a>
              </Link>
              <div
                id="collapseOrderManage"
                className="collapse"
                aria-labelledby="headingOrderManage"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {order_manage.read === true ? (
                    <>
                      <Link to="/manage_orders">
                        <a href="replace" className="collapse-item">
                          Orders List
                        </a>
                      </Link>
                      <Link to="/cancelreturn">
                        <a href="replace" className="collapse-item">
                          Partially Cancel & Return
                        </a>
                      </Link>
                    </>
                  ) : null}
                </div>
              </div>
            </li>
          </>
        ) : order_manage.read ? (
          <>
            <li className="nav-item">
              <Link to="#">
                <a
                  className="nav-link collapsed"
                  href="replace"
                  data-toggle="collapse"
                  data-target="#collapseOrderManage"
                  aria-expanded="true"
                  aria-controls="collapseOrderManage"
                >
                  <i className="fas fa-fw fa-cog"></i>
                  <span>Manage Orders</span>
                </a>
              </Link>
              <div
                id="collapseOrderManage"
                className="collapse"
                aria-labelledby="headingOrderManage"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  {order_manage.read === true ? (
                    <>
                      <Link to="/manage_orders">
                        <a href="replace" className="collapse-item">
                          Orders List
                        </a>
                      </Link>
                      <Link to="/cancelreturn">
                        <a href="replace" className="collapse-item">
                          Partially Cancel & Return
                        </a>
                      </Link>
                    </>
                  ) : null}
                </div>
              </div>
            </li>
          </>
        ) : null}
        {/* { isAdmin === "true" ? ( */}
        <hr className="sidebar-divider" />
        <div className="sidebar-heading">UserManagement</div>
        <li className="nav-item">
          <Link
            to="#"
            className="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <i className="fas fa-fw fa-cog"></i>
            <span>Users</span>
          </Link>
          <div
            id="collapseOne"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              {isAdmin === "true" ? (
                <Link to="/adduser">
                  <a href="replace" className="collapse-item">
                    Add user
                  </a>
                </Link>
              ) : user.write ? (
                <Link to="/adduser">
                  <a href="replace" className="collapse-item">
                    Add user
                  </a>
                </Link>
              ) : null}
              {isAdmin === "true" ? (
                <Link to="/userlist">
                  <a href="replace" className="collapse-item">
                    User List
                  </a>
                </Link>
              ) : user.read ? (
                <Link to="/userlist">
                  <a href="replace" className="collapse-item">
                    User List
                  </a>
                </Link>
              ) : null}
            </div>
          </div>
        </li>
        <hr className="sidebar-divider" />
        <div className="sidebar-heading">Search</div>
        <li className="nav-item">
          <Link
            to="#"
            className="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapsetwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <i className="fas fa-fw fa-cog"></i>
            <span>Search</span>
          </Link>
          <div
            id="collapsetwo"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              {isAdmin === "true" ? (
                <>
                  <Link to="/searchkeyword">
                    <a href="replace" className="collapse-item">
                      Search Keyword
                    </a>
                  </Link>

                  <Link to="/searchlog">
                    <a href="replace" className="collapse-item">
                      Search Logs
                    </a>
                  </Link>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </li>
        <hr className="sidebar-divider d-none d-md-block" />

        <div className="sidebar-heading">Query</div>
        {/*  SupportListing */}

        <li className="nav-item">
          <Link to="#">
            <a
              className="nav-link collapsed"
              href="replace"
              data-toggle="collapse"
              data-target="#collapsefour"
              aria-expanded="true"
              aria-controls="collapsefour"
            >
              <i className="fas fa-fw fa-cog"></i>
              <span>Support</span>
            </a>
          </Link>
          <div
            id="collapsefour"
            className="collapse"
            aria-labelledby="headingfour"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              {isAdmin === "true" ? (
                <Link to="/supportlist">
                  <a href="replace" className="collapse-item">
                    Support List
                  </a>
                </Link>
              ) : order.read ? (
                <Link to="/supportlist">
                  <a href="replace" className="collapse-item">
                    Support List
                  </a>
                </Link>
              ) : null}
            </div>
          </div>
        </li>

        {/* Grievance */}
        <hr className="sidebar-divider" />
        <div className="sidebar-heading">Grievance Management</div>
        <li className="nav-item">
          <Link
            to="#"
            className="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseGrievance"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <i className="fas fa-fw fa-cog"></i>
            <span>IGM Portal</span>
          </Link>
          <div
            id="collapseGrievance"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              {isAdmin === "true" ? (
                <>
                  <Link to="/issueslist">
                    <a href="replace" className="collapse-item">
                      IGM
                    </a>
                  </Link>
                  <Link to="/grievancesettings">
                    <a href="replace" className="collapse-item">
                    Grievance Settings
                    </a>
                  </Link>
                </>
              ) : (igm?.read === true || igm?.write === true) ? (
                // {
                (igm?.read === true && igm?.write === true) ? (
                  <>
                    <Link to="/issueslist">
                      <a href="replace" className="collapse-item">
                        IGM
                      </a>
                    </Link>
                    <Link to="/grievancesettings">
                    <a href="replace" className="collapse-item">
                    Grievance Settings
                    </a>
                  </Link>
                  </>
                ) : (igm?.read === true) ? (
                  <>
                    <Link to="/issueslist">
                      <a href="replace" className="collapse-item">
                        IGM
                      </a>
                    </Link>
                    <Link to="/grievancesettings">
                    <a href="replace" className="collapse-item">
                    Grievance Settings
                    </a>
                  </Link>
                  </>
                ) : null
              ) : null
              }
            </div>
          </div>
        </li>

        {/*  SupportListing */}
        <hr className="sidebar-divider d-none d-md-block" />
        {/* txnid */}
        <li className="nav-item">
          <Link to="#">
            <a className="nav-link collapsed" data-toggle="collapse"
              data-target="#collapsefive"
              aria-expanded="true"
              aria-controls="collapsefive">
              <i className="fas fa-fw fa-cog"></i>
              <span>Txn_Id Search</span>
            </a>
          </Link>
          <div
            id="collapsefive"
            className="collapse"
            aria-labelledby="headingfour"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              {isAdmin === "true" ? (
                <Link to="/search_txnlog">
                  <a className="collapse-item">Search By Txnid</a>
                </Link>
              ) : order?.read ? (
                <Link to="/search_txnlog">
                  <a className="collapse-item">Search By Txnid </a>
                </Link>
              ) : null}
            </div>
          </div>
        </li>
        {/* txnid */}
      </ul>
    </>
  );
};

export default Sidebar;
