import ThemeMaker from "../../components/thememaker";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "../searchKeyword/search.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { response } from "express";
import { data } from "jquery";
import jwt_decode from "jwt-decode";
import config from "../../config/index";
var $ = require("jquery");

const SearchKeyword = () => {
  const {baseurl} = config
  $(document).ready(function () {
    $("table.display").DataTable();
  });

  const [search, setSearch] = useState([]);
  const [searchKey, setSearchKey] = useState([]);
  const [dispdata, setDispdata] = useState([]);

  var i = 1;
  const token: any = sessionStorage.getItem("isLogedIn");
  const decoded: any = jwt_decode(token);


  // Search item by name

  const searchRecords = async () => {
    const { data } = await axios({
      url: `${baseurl}/keywordfind`,
      method: "POST",
      headers: {
        Authorization: ` ${token}`
      },
      data: {
        "searchkeyword": searchKey
      }
    });
    setDispdata(data.data)
    console.log(data);

  };

  return (
    <ThemeMaker>
      <div className="container-fluid">
        <h4 className="text-center text-success mt-5">
          <b>Search Your Keyword</b>
        </h4>
        <div className="form-outline harish111111">
          <input
            type="text"
            id="form1"
            onChange={(e: any) => setSearchKey(e.target.value)}
            className="form-control"
            placeholder="Search Keyword"
          />
        </div>
        <button
          type="button"
          onClick={searchRecords}
          className="btn btn-success btn1111"
        >
          Search
        </button>
      </div>

      <table className="table table-striped">
        <thead>
          <tr>
            <th>Sr.No.</th>
            <th>Keyword</th>
            <th>Count</th>
          </tr>
        </thead>

        <tbody>
          {
            dispdata &&
            dispdata.map((item: any,) => (
              <tr>
                <td>{i++}</td>
                <td>
                  <h6>{item.keyword}</h6>
                </td>
                <td>
                  <h6>{item.count}</h6>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </ThemeMaker>
  );
};

export default SearchKeyword;
