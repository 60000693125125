import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import config from "../../config/index";
import jwt_decode from "jwt-decode";


const Login = () => {
    const {baseurl} = config
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({
        email: "",
        password: ""
    });
    const [errorMessage, setErrorMessage] = useState({
        email: true,
        password: true
    });
    
    // const token: any = sessionStorage.getItem("isLogedIn");
    // const decoded: any = jwt_decode(token);
    // const { data } = decoded;

    const redirectHandler = (token: string) => {
        //console.log(token)
        sessionStorage.setItem("isLogedIn", token);
        setLoading(false);
        history.push(`/`);
    }
  

    const loginMannage = async () => {
        try {
            setLoading(true);
            const { data: { status, message, token } } = await axios({
                url: `${baseurl}/login`,
                method: "POST",
                // headers: {
                //     Authorization: ` ${token}`
                // },
                data: user
            });
            // console.log(status, message, token);
            
            setLoading(false);
            switch (message) {
                case "flag3":
                    return toast.error("validation failed !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                case "flag0":
                    return toast.error("All fields are required !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                case "flag00":
                    return toast.error("Server is down !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                case "flag000":
                    return toast.error("Please check your email !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                case "flag0000":
                    return toast.error("Wrong password !", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                case "flag1":
                    return redirectHandler(token);


            }
        } catch (error) {
            setLoading(false);
            toast.error("404 error !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const loginChecker = (e: any) => {
        e.preventDefault();
        if (errorMessage.email || errorMessage.password) {
            toast.error("Email and password is required !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            loginMannage();
        }
    }
    const onChangeHandler = (e: any) => {
        setUser({ ...user, [e.target.name]: e.target.value });
        setErrorMessage({ ...errorMessage, [e.target.name]: e.target.value ? false : true })
    }
    return (
        <>

            <div className="container-fluid bg-primary" style={{ height: "100vh" }} >

                <div className="row justify-content-center">

                    <div className="col-md-6 mt-4 mb-4">

                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="p-5">
                                            <div className="text-center mb-3">
                                                <img src="/img/ens-logo-.png" style={{ height: "65px" }} alt="logo here" />
                                            </div>
                                            <div className="text-center">
                                                <h1 className="h4 text-primary mt-4 mb-4">ONDC | The U Shop | Admin</h1>
                                            </div>
                                            <form className="user mb-4" onSubmit={(e) => loginChecker(e)} >
                                                <div className="form-group">
                                                    <input type="email" className="form-control form-control-user"

                                                        placeholder="Enter Email Address..."
                                                        name="email"
                                                        onChange={(e) => onChangeHandler(e)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <input type="password" className="form-control form-control-user"
                                                        placeholder="Password"
                                                        name="password"
                                                        onChange={(e) => onChangeHandler(e)}
                                                    />
                                                </div>
                                                {
                                                    loading ? <button className="btn btn-primary btn-user btn-block mt-4 mb-4">
                                                        Loading...
                                                    </button> : <button className="btn btn-primary btn-user btn-block mt-4 mb-4">
                                                        Login
                                                    </button>
                                                }


                                            </form>
                                            <hr />
                                            <div className="text-center">
                                                <Link className="small" to="/forget-password">Forgot Password?</Link>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <ToastContainer />
        </>
    )
}

export default Login;