import { useState, useEffect, useRef } from "react";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import jwt_decode from "jwt-decode";
import config from "../../config/index";


const Dashboard = () => {

    const [orderList, setOrderList] = useState([]);
    const {baseurl} = config
    const [userList, setUserList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [supportList, setSupportList] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [loading, setloading] = useState(true);
    const closeModel: any = useRef();
    const [orderValue, setOrderValue] = useState(0);
    const [averageValue, setAverageValue] = useState(0);
    const token: any = sessionStorage.getItem("isLogedIn");
    const decoded: any = jwt_decode(token);
    const { data } = decoded;
    const [_id, setId] = useState("");
    const [error, setError] = useState(' ');

    const getProductList = async () => {
        try {
            const { data: { data } } = await axios({
                url: `${baseurl}/product`,
                method: "GET",
                headers: {
                    Authorization: `${token}`
                },
            });
            setProductList(data);
            setloading(false);
            // console.log("Active/draft=>", data.length)
            console.log(data.filter((item: any) => item.status === 'active').length);
        } catch (error) {
            setloading(false);
            setError("error");
        }
    }

    const getUserList = async () => {
        try {
            const { data: { data } } = await axios({
                url: `${baseurl}/user/list`,
                method: "GET",
                headers: {
                    Authorization: ` ${token}`
                },
            });
            setUserList(data)
            setloading(false);
            // console.log("usersLisr>", data)
        } catch (error) {
            setloading(false);
            setError("error");
        }
    }

    const getOrderList = async () => {
        try {
            const {
                data: { data },
            } = await axios({
                url: `${baseurl}/orders/getallorders`,
                method: "GET",
                headers: {
                    Authorization: ` ${token}`
                },
            });
            setOrderList(data);
            const totalOrderValue = data.reduce((total: any, item: any) => {
                const priceValue = parseFloat(item?.response?.message?.order?.quote?.price?.value) || 0;
                return total + priceValue;
            }, 0);
            setOrderValue(totalOrderValue);
            setAverageValue(totalOrderValue / (data.length))
            // console.log("totalOrderValue", totalOrderValue);
            // console.log("orderList>>>", data)
            setloading(false);
        } catch (error) {
            setloading(false);
            setError("error");
        }
    };


    const getSupportList = async () => {
        try {
            const {
                data: { data },
            } = await axios({
                url: `${baseurl}/orders/getsupport`,
                method: "GET",
                headers: {
                    Authorization: ` ${token}`
                },
            });
            setSupportList(data);
            setloading(false);
            // console.log("support?????????", data)
        } catch (error) {
            setloading(false);
            setError("error");
        }
    };


    useEffect(() => {
        getOrderList();
        getUserList()
        getProductList();
        getSupportList();
    }, [])


    return (

        <ThemeMaker>
            <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>

                </div>

                <div className="row">

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Total Active Products</div>



                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{productList?.filter((item: any) => item.status === 'active').length}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-success shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Total Users</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{userList?.length}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-info shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Total Order_s
                                        </div>
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-auto">
                                                <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{orderList?.length}</div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-warning shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                            Pending Requests</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{supportList?.length}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-comments fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Total Order Value</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{orderValue}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-success shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Average Order Value</div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{averageValue?.toFixed(2)}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </ThemeMaker>


    )
}

export default Dashboard; 