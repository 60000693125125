import { toast } from "react-toastify"
import ThemeMaker from "../../components/thememaker"
import { useState, useEffect } from "react"
import axios from "axios"
import jwt_decode from "jwt-decode";
import config from "../../config/index";

export default function IgmSetting() {
    const {baseurl} = config
    const token: any = sessionStorage.getItem("isLogedIn");
    const decoded: any = jwt_decode(token);
    const [formData, setformData] = useState({
        org_person_name: "",
        org_phone: "",
        org_email: "",
        id: ""
    })

    async function getOrganizationDetails() {
        try {
            const response = await axios({
                url: `${baseurl}/v1/getorgDetails`,
                method: "GET",
                headers: {
                    Authorization: token
                },
            });
            if (response && response?.data) {
                const { data }: any = response?.data;
                setformData(prev => ({
                    ...prev,
                    org_person_name: data.org_person_name || '',
                    org_phone: data.org_phone || '',
                    org_email: data.org_email || '',
                    id: data._id || '',
                }));
            } else {
                console.log("igmDetails is undefined or null");
            }

        } catch (error) {
            console.error("Error fetching organization details:", error);
        }
    }


    function onChangeHandler(e: any) {
        setformData((prev) => {
            return {
                ...prev, [e.target.name]: e.target.value
            }
        })
    }

    async function submitHandler() {
        let { org_person_name, org_email, org_phone, id } = formData
        if (org_person_name === "" || org_email === "" || org_phone === "") {
            return toast.error("Please Fill All Details")
        }
        try {
            debugger
            await axios({
                url: `${baseurl}/v1/organizations_details?id=${id}`,
                method: "POST",
                data: {
                    "org_phone": org_phone,
                    "org_email": org_email,
                    "org_person_name": org_person_name
                },
                headers: {
                    Authorization: token
                },
            })

            toast.success("Data Saved Successfully!")
        } catch (err) {
            toast.error("Something Went Wrong! Please try again later.")
        }
    }

    const preventMinus = (e: any) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };
    useEffect(() => {
        getOrganizationDetails()
    }, [])

    console.log("formData", formData)
    return (
        <ThemeMaker>
            <div className="row w-100 m-0 mt-1">
                <div className="col-md-12 ">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary text-left d-flex justify-content-between align-items-center">Grievance Settings</h6>
                        </div>
                        <div className="card-body" style={{ minHeight: "70vh" }}>
                            <h6 className="m-0 font-weight-bold text-primary text-left d-flex justify-content-between align-items-center"> Organization Details</h6>
                            <div className="row mt-4">


                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="genericlabel">
                                            Organization Name
                                            <span className="text-danger" > * </span>
                                        </label>
                                        <input type="text" name="org_person_name" className="form-control" placeholder="Enter Name" value={formData.org_person_name} onChange={onChangeHandler} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="genericlabel">
                                            Organization Email
                                            <span className="text-danger" > * </span>
                                        </label>
                                        <input type="text" name="org_email" className="form-control" placeholder="Enter Email" value={formData.org_email} onChange={onChangeHandler} />
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="genericlabel">
                                            Organization Contact Number
                                            <span className="text-danger" > * </span>

                                        </label>
                                        <input type="number" name="org_phone" className="form-control" placeholder="Enter Contact" value={formData.org_phone} onKeyPress={preventMinus} onChange={onChangeHandler} />
                                    </div>
                                </div>
                            </div>
                            <div className="row px-2 ">
                                <button className="btn btn-primary" onClick={submitHandler}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ThemeMaker>
    )
}