import { useState, useEffect, useRef, CSSProperties } from "react";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Loader from "../../components/tabelloader";
import jwt_decode from "jwt-decode";
import { Paginated } from "../../components/Paginated";
import ThemeMaker from "../../components/thememaker";
import Barcode from "../../components/Barcode"
import config from "../../config/index";
var $ = require('jquery');

const styles = {
    productImgStyle: {
        height: "50px",
    } as CSSProperties,
    priceStyle: {
        whiteSpace: "nowrap"
    } as CSSProperties,
};

const ProductList = () => {
    const {baseurl} = config
    const token: any = sessionStorage.getItem("isLogedIn");
    const decoded: any = jwt_decode(token);
    //console.log(decoded.data.permission.user);
    const { user, product } = decoded.data.permission;
    const [SearchList, setSearchList] = useState([]);
    const history = useHistory();
    const [productList, setProductList] = useState([]);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [loading, setloading] = useState(true);
    const closeModel: any = useRef();

    const [error, setError] = useState('');
    const [_id, setId] = useState("");

    const getProductList = async () => {
        try {
            const { data: { data } } = await axios({
                url: `${baseurl}/product`,
                method: "GET",
                headers: {
                    Authorization: ` ${token}`
                },
            });
            setProductList(data);
            setSearchList(data)
            setloading(false);
            setTimeout(() => {
                $('#dataTable').DataTable();
            }, 1000)
        } catch (error) {
            setloading(false);
            setError("error");
        }
    }
    const deleteProduct = async () => {
        try {
            setDeleteLoading(true);
            console.log(_id);

            const { data } = await axios({
                url: `${baseurl}/product/delete`,
                method: "DELETE",
                headers: {
                    Authorization: ` ${token}`
                },
                data: { _id }
            });
            setDeleteLoading(false);
            setProductList(productList.filter((itm: any) => itm._id !== _id));
            getProductList();
            closeModel.current.click();
        } catch (error) {
            setDeleteLoading(false);
        }
    }


    const redirectToUserPage = (_id: any) => {
        const product = productList.find((item: any) => item._id === _id);
        localStorage.setItem('product', JSON.stringify(product));
        history.push(`/product/${_id}`);
    }

    const filterBySearch = (event: any) => {
        debugger
        const querySearch = event.target.value;
        let SearchFiltere = productList.filter((item: any) => {
            const priceAsString = String(item?.price);
            const lowerCasedQuery = querySearch.toLowerCase();
            return (
                item?.title?.toLowerCase()?.includes(querySearch?.toLowerCase()) ||
                item?.status?.toLowerCase()?.includes(querySearch?.toLowerCase()) ||
                item?.vendor?.toLowerCase()?.includes(querySearch?.toLowerCase()) ||
                item?.barcode?.toLowerCase()?.includes(querySearch?.toLowerCase()) ||
                item?.productid?.toLowerCase()?.includes(querySearch?.toLowerCase()) ||
                item?.hscode?.toLowerCase()?.includes(querySearch?.toLowerCase()) ||
                item?.sku?.toLowerCase()?.includes(querySearch?.toLowerCase()) ||
                item?.category_id?.toLowerCase()?.includes(querySearch?.toLowerCase()) ||
                priceAsString.includes(lowerCasedQuery)
            );
        });
        setSearchList(SearchFiltere);
    };

    const COLUMNS = [
        {
            Header: "Sn.",
            accessor: (row: any, count: any) => {
                return <>{count + 1}</>
            },
            disableFilters: false,
            sticky: "left"
        },
        {
            Header: "Images",
            accessor: (item: any) => (item && item.images && item.images[0] ? (<img src={item.images[0]} alt="Product..."
                style={styles?.productImgStyle}
            />) : null),
            sticky: "left"
        },
        {
            Header: "Title",
            accessor: (item: any) => (item?.title ? item?.title : null),
            sticky: "left"
        },
        {
            Header: "Bar Code",
            accessor: (item: any) => (item?.barcode ? `${item?.barcode}` : null),
            sticky: "left"
        },
        // {
        //     Header: "Bar Code",
        //     accessor: "barcode",
        //     Cell: ({ cell: { value } }: any) => {
        //         return value ? <Barcode value={value} /> : null;
        //     },
        //     sticky: "left",
        // },
        {
            Header: "Price",
            accessor: (item: any) => (item?.price ? `${item?.price} ₹` : null),
            Cell: ({ value }: any) => (
                <div className="price-cell" style={styles?.priceStyle}>
                    {value}
                </div>
            ),
            sticky: "left",
        },
        {
            Header: "Product ID",
            accessor: (item: any) => (item?.productid ? `${item?.productid}` : null),
            sticky: "left"
        },
        {
            Header: "Category ID",
            accessor: (item: any) => (item?.category_id ? `${item?.category_id}` : null),
            sticky: "left"
        },
        {
            Header: "HS Code",
            accessor: (item: any) => (item?.hscode ? `${item?.hscode}` : null),
            sticky: "left"
        },
        {
            Header: "SKU",
            accessor: (item: any) => (item?.sku ? `${item?.sku}` : null),
            sticky: "left"
        },
        {
            Header: "Vendor",
            accessor: (item: any) => (item?.vendor ? `${item?.vendor}` : null),
            sticky: "left"
        },
        {
            Header: "Publish Status",
            accessor: (item: any) => (
                item?.status === "active" ? (
                    <span className="badge badge-success">Active</span>
                ) : (
                    <span className="badge badge-secondary">Draft</span>
                )
            ),
            sticky: "left"
        },
        // {
        //     Header: "Action",
        //     accessor: (item: any) => {
        //         if (!item?._id) return null;

        //         const isAdmin = decoded?.data?.isAdmin === "true";
        //         const canEdit = isAdmin || (product?.update && item?._id);
        //         const canDelete = isAdmin || (product?.delete && item?._id);
        //         return (
        //             <div className="row" style={{ width: "120px" }}>
        //                 <div className="col-md-12">
        //                     {canEdit && (
        //                         <Link to="#" onClick={() => redirectToUserPage(item?._id)} className="btn btn-primary justify-content-start btn-circle">
        //                             <i className={isAdmin ? "fa fa-edit" : "fas fa-edit"}></i>
        //                         </Link>
        //                     )}
        //                     {canDelete && (
        //                         <button
        //                             className="btn btn-danger ml-2"
        //                             data-toggle="modal"
        //                             data-target="#exampleModal"
        //                             onClick={() => setId(item?._id)}
        //                         >
        //                             <i className="fas fa-trash"></i>
        //                         </button>
        //                     )}
        //                 </div>
        //             </div>
        //         );
        //     },
        //     sticky: "left",
        // }
        {
            Header: "Action",
            accessor: (item:any) => (
                <div className="row" style={{ width: "120px" }}>
                    <div className="col-md-12">
                        {
                            decoded.data.isAdmin === "true" || product.update ? (
                                <Link to={`#`} onClick={() => redirectToUserPage(item._id)} className="btn btn-primary btn-circle">
                                    <i className="fas fa-edit"></i>
                                </Link>
                            ) : null
                        }
                        {
                            decoded.data.isAdmin === "true" || product.delete ? (
                                <button className="btn btn-danger btn-circle ml-2"
                                    data-toggle="modal" data-target="#exampleModal"
                                    onClick={() => setId(item._id)}
                                >
                                    <i className="fas fa-trash"></i>
                                </button>
                            ) : null
                        }
                    </div>
                </div>
            ),
            sticky: "left"
        }
        
    ];

    useEffect(() => {
        getProductList();
    }, [])
    return (
        <>
            <ThemeMaker>
                <div className="card-header">
                    <h6 className="m-0 font-weight-bold text-primary text-left">Products List</h6>
                    <div className="search mt-1">
                        <div className="row m-0 d-flex justify-content-center">
                            <div className="d-flex w-100">
                                <div className="col-md-4 p-0">
                                    <label htmlFor="exampleInputEmail1">Search Products</label>
                                    <input type="search" name="order_id" placeholder="Search" className="mr-2 form-control"
                                        onChange={(event) => filterBySearch(event)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive table-bordered">
                        <Paginated data={SearchList} columns={COLUMNS} />
                        {/* {
                            loading ? <Loader /> : (
                                <table className="table table-bordered" id="dataTable" width="100%" >
                                    <thead>
                                        <tr>
                                            <th>Sn</th>
                                            <th>Image</th>
                                            <th>Title</th>
                                            <th>Price</th>
                                            <th>Product Id</th>
                                            <th>Bar Code</th>
                                            <th>Category Id</th>
                                            <th>HS Code</th>
                                            <th>SKU</th>
                                            <th>Vendor</th>
                                            <th>Publish status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                    </tfoot>
                                    <tbody>
                                        {
                                            productList.map((item: any, index) => (
                                                <tr key={item._id} >
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <div>
                                                            <img src={`${'/img/undraw_profile.svg'}`} alt="noimh" style={{ height: "50px" }} />
                                                        </div>
                                                    </td>
                                                    <td>{item?.title}</td>
                                                    <td>{item?.price}</td>
                                                    <td>{item?.productid}</td>
                                                    <td>{item?.barcode}</td>
                                                    <td>{item?.category_id}</td>
                                                    <td>{item?.hscode}</td>
                                                    <td>{item?.sku}</td>
                                                    <td>{item?.vendor}</td>
                                                    <td>{
                                                        item?.status === "active" ? (
                                                            <span className="badge badge-success">Active</span>
                                                        ) : (
                                                            <span className="badge badge-secondary">Draft</span>
                                                        )

                                                    }

                                                    </td>


                                                    <td>
                                                        <div className="row" style={{ width: "120px" }} >
                                                            <div className="col-md-12">
                                                                {
                                                                    decoded.data.isAdmin === "true" ? (
                                                                        <Link to={`#`} onClick={() => redirectToUserPage(item._id)} className="btn btn-primary btn-circle">
                                                                            <i className="fas fa-exclamation-triangle"></i>
                                                                        </Link>
                                                                    ) : product.update ? <Link to={`#`} onClick={() => redirectToUserPage(item._id)} className="btn btn-primary btn-circle">
                                                                        <i className="fas fa-exclamation-triangle"></i>
                                                                    </Link> : null
                                                                }
                                                                {
                                                                    decoded.data.isAdmin === "true" ? (
                                                                        <button className="btn btn-danger btn-circle ml-2"
                                                                            data-toggle="modal" data-target="#exampleModal"
                                                                            onClick={() => setId(item._id)}
                                                                        >
                                                                            <i className="fas fa-trash"></i>
                                                                        </button>
                                                                    ) : product.delete ? <button className="btn btn-danger btn-circle ml-2"
                                                                        data-toggle="modal" data-target="#exampleModal"
                                                                        onClick={() => setId(item._id)}
                                                                    >
                                                                        <i className="fas fa-trash"></i>
                                                                    </button> : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            )
                        } */}
                    </div>
                </div>
                <div className="modal fade" id="exampleModal" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                {/* <h5 className="modal-title text-center"></h5> */}
                                <button type="button" ref={closeModel} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure want to delete !</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger"
                                    onClick={deleteProduct}
                                >
                                    {
                                        deleteLoading ? "Loading..." : "Delete"
                                    }

                                </button>
                                {
                                    deleteLoading ? null : (
                                        <button type="button" id="closebtn" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeMaker>
        </>
    )
}

export default ProductList;