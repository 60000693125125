import { useState, useEffect, useRef, CSSProperties } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "../manage_orderlist/orderlistPopus.css";
import Loader from "../../components/tabelloader";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import ThemeMaker from "../../components/thememaker";
import { format } from "date-fns";
import moment from "moment";
import { Modal as AntModal } from "antd"
import { Paginated } from "../../components/Paginated";
import CircularProgress from "@mui/material/CircularProgress";
import config from "../../config/index";
var $ = require("jquery");

const styles = {
  detailsStyle: {
    display: 'grid',
    justifyItems: "stretch",
    alignItems: "center",
  } as CSSProperties,
  headingStyle: {
    color: "#a00505e0",
    display: "flex",
    justifyContent: "space-between"
  } as CSSProperties,
}

const OrderList = () => {
  const {baseurl} = config;
  const [popup, setPopup] = useState(false);
  const [orderStatusPopup, setOrderStatusPopup] = useState(false);
  const [cancelOrderPopup, setCancelOrderPopup] = useState(false);
  const [orderFulfillmentPopup, setOrderFulfillmentPopup] = useState(false);
  const [orderPopup, setOrderPopup] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [context, setContext] = useState({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const token: any = sessionStorage.getItem("isLogedIn");
  const decoded: any = jwt_decode(token);
  // console.log('decoded.data.permission.user 23=>', decoded.data.permission.user);
  // console.log('decoded.data.permission 25=>', decoded.data.permission);
  const { user, product, order, order_manage } = decoded.data.permission;
  const history = useHistory();
  const [orderList, setOrderList] = useState([]);
  const [manageFilter, setManageFilter] = useState([]);
  const [downloadcsv, setDownloadcsv] = useState({});
  const [loading, setloading] = useState(true);
  const closeModel: any = useRef();
  const [error, setError] = useState("");
  const [_id, setId] = useState("");
  const [fullFillmentStatus, setFullFillmentStatus] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [status, setstatus] = useState();
  const [reason_code, setreason_code] = useState();
  const [orderid, setorderid] = useState("");
  const [shopifyorderid, setshopifyorderid] = useState("");
  const [dynamicValue, setDynamicValue] = useState("In-progress");



  const handleClickPop = async (id: any) => {
    setPopup(!popup);
    try {
      const { data } = await axios({
        url: `${baseurl}/orders/order_details`,
        method: "POST",
        headers: {
          Authorization: ` ${token}`
        },
        data: {
          order_id: id,
        },
      });
      // console.log("sas => ", data);
      if (data.status === 200) {
        setOrderPopup(data.data.reverse());
      } else {
        toast.error("404 not found !");
      }
      setloading(false);
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 1000);
      // console.log("data>>>>>>", data);
    } catch (error) {
      setloading(false);
    }
  };

  const closePopup = () => {
    setPopup(false);
  };

  const getOrderList = async () => {
    try {
      const {
        data: { data },
      } = await axios({
        url: `${baseurl}/orders/npdata`,
        method: "GET",
        headers: {
          Authorization: ` ${token}`
        },
      });
      setOrderList(data?.reverse());
      setManageFilter(data?.reverse());
      setloading(false);
      setTimeout(() => {
        $("#dataTable").DataTable();
      }, 1000);
    } catch (error) {
      setloading(false);
      setError("error");
    }
  };
  const DownloadCsv = async () => {
    // console.log("file download")
    try {
      const {
        data: { data },
      } = await axios({
        url: `${baseurl}/orders/exportcsv`,
        // url:"localhost:9095/orders/exportcsv",
        method: "GET",
        headers: {
          "Content-Type": "application/csv",
          Authorization: ` ${token}`
        },
      });

      console.log(data);
      setDownloadcsv(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderList();
    //  DownloadCsv();
  }, []);

  useEffect(() => {
    // console.log("status", status);
    if (status === "Accepted") {
      setFullFillmentStatus("Pending");
    }
    if (status === "In-progress") {
      setFullFillmentStatus("Order-picked-up");
    }
    if (status === "Completed") {
      setFullFillmentStatus("Order-delivered");
    }
    if (status === "Cancelled") {
      setFullFillmentStatus("Cancelled");
    }
  }, [status]);

  const changeStatus = async () => {
    try {
      const { data } = await axios({
        url: `${baseurl}/orders/orderstatus`,
        method: "PUT",
        headers: {
          Authorization: ` ${token}`
        },
        data: {
          order_id: orderid,
          orderStatus: status,
          fullFillmentStatus: fullFillmentStatus,
          timestamp: timestamp,
        },
      });
      if (data.status === 200) {
        setUpdating(true);
        toast.success("Order status updated !");
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setOrderStatusPopup(false);
        await changeStatusseller(orderid, context);
        setUpdating(false);
      } else {
        toast.error("404 not found !");
      }
      // setTimeout(() => {
      //   $("#dataTable").DataTable();
      // }, 1000);
      setUpdating(false);
    } catch (error) { }
  };
  const handleOk = () => {
    setOrderStatusPopup(false);
  };
  const handleCancel = () => {
    setOrderStatusPopup(false);
  };
  const changeStatusseller = async (orderid: any, context: any) => {
    // console.log("orderId", orderid);
    // console.log("context", context);
    context.message_id = uuidv4();
    const contextdata = {
      context,
      message: {
        order_id: orderid,
      },
    };
    console.log("contaxtdata", contextdata);
    try {
      const { data } = await axios({
        url: `${baseurl}/dashboard_status`,
        // url: `https://ondc.hulsecure.in/preprod/v1/status`,
        method: "POST",
        headers: {
          Authorization: ` ${token}`
        },
        data: contextdata,
      });
      // console.log("sas => ", data);
      toast.success("Status updated by seller app");
      // console.log("data>>>>>>", data);
    } catch (error: any) {
      console.log("error", error);
      toast.error(`Error : ${error.message}`);
    }
  };

  const changeUpdate_Unsolicated = async (ondcorderid: any) => {
    // console.log("contaxtdata22", ondcorderid);
    try {
      const { data } = await axios({
        url: `${baseurl}/Unsolicated_Update`,
        // url: `https://ondc.hulsecure.in/Unsolicated_Update`,
        method: "POST",
        headers: {
          Authorization: token
        },
        data: { ondcorderid },
      });
      // console.log("sas => ", data);
      toast.success("Status updated by seller app");
      // console.log("data>>>>>>", data);
    } catch (error: any) {
      console.log("error", error);
      toast.error(`Error : ${error.message}`);
    }
  };

  const ChangeStatusPopup = (
    ondcorderid: any,
    orderStatus: any,
    context: any
  ) => {
    // console.log(">>>>>>>>>>", orderStatus);
    setOrderStatusPopup(true);
    setorderid(ondcorderid);
    setContext(context);
    setstatus(orderStatus);
    setDynamicValue(orderStatus);
  };

  const closePop = () => {
    setOrderStatusPopup(false);
  };

  const CancelOrderPopup = (
    ondcorderid: any,
    shopifyorderid: any
  ) => {
    setCancelOrderPopup(true);
    setorderid(ondcorderid);
    setshopifyorderid(shopifyorderid);
    setreason_code(reason_code);
  };
  const closeCancelPop = () => {
    setCancelOrderPopup(false);
  };
  const cancelOrder = async () => {
    // console.log("ondcorderid", orderid);
    // console.log("shopifyorderid", shopifyorderid);
    // console.log("line no 45 reasion code ", reason_code)
    const confirmed = window.confirm(
      "Are you sure you want to cancel this order?"
    );
    if (confirmed) {
      try {
        const { data } = await axios({
          url: `${baseurl}/orders/cancelorder`,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: ` ${token}`
          },
          data: {
            shopifyorderid: shopifyorderid,
            reason_code: reason_code
          },
        });
        console.log("sas => ", data);
        if (data.status === 200) {
          toast.success("Order Cancelled");
        } else {
          toast.error("404 not found!");
        }
        console.log("data>>>>>>", data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Filter
  const handleStartDateChange = (event: any) => {
    setStartDate(event.target.value);
    filterData(event.target.value, endDate);
  };

  const handleEndDateChange = (event: any) => {
    setEndDate(event.target.value);
    filterData(startDate, event.target.value);
  };
  // filter By Dates
  const filterData = (start: any, end: any) => {
    const startFilter = start ? new Date(start) : null;
    const endFilter = end ? new Date(end) : null;
    const filtered = orderList.filter((item: any) => {
      const itemDate = new Date(item.createdAt);
      return (
        (!startFilter || itemDate >= startFilter) && (!endFilter || itemDate <= endFilter)
      );
    });
    setManageFilter(filtered);
  };

  // FILTER
  const filterBySearch = (event: any) => {
    const querySearch = event?.target?.value?.toLowerCase();
    let SearchFiltere = orderList.filter((item: any) => {
      return (
        (item?.status?.toLowerCase()?.includes(querySearch)) ||
        (item?.ondcorderid?.toLowerCase()?.includes(querySearch)) ||
        (item?.shopifyorderid?.toLowerCase()?.includes(querySearch)) ||
        (item?.response?.context?.bap_id?.toLowerCase()?.includes(querySearch)) ||
        (item?.response?.message?.order?.items[0]?.fulfillment_id?.toString()?.toLowerCase()?.includes(querySearch)) ||
        (item?.response?.message?.order?.quote?.breakup[0]?.title?.toString()?.toLowerCase()?.includes(querySearch)) ||
        (item?.response?.message?.order?.billing?.address?.area_code?.toString()?.toLowerCase()?.includes(querySearch)) ||
        (item?.response?.message?.order?.payment?.status?.toString()?.toLowerCase()?.includes(querySearch)) ||
        (item?.response?.context?.bap_id?.toString()?.toLowerCase()?.includes(querySearch)) ||
        (item?.delivery_city?.toString()?.toLowerCase()?.includes(querySearch)) ||
        (item?.shopifyorderef?.toString()?.toLowerCase()?.includes(querySearch)) ||
        (item?.sku?.toString()?.toLowerCase()?.includes(querySearch)) ||
        (`${item?.response?.message?.order?.billing?.name?.slice(0, 4)}-${item?.response?.message?.order?.billing?.phone?.slice(-5)}`?.toString()?.toLowerCase()?.includes(querySearch)) ||
        (item?.response?.context?.transaction_id?.toString()?.toLowerCase()?.includes(querySearch))
      );
    });
    setManageFilter(SearchFiltere);
  };
  const calculateAgeing = (row: any) => {
    const date1 = moment(row.createdAt);
    const date2 = moment(row.status === "Completed" || row.status === "Cancelled" ? row.updatedAt : new Date());
    return date2.diff(date1, 'days');
  };

  function selectOrderStatusHandler(e: any) {
    let allorders = [...orderList];
    let filteredOrders = allorders.filter((item: any) => item?.status === e.target.value);
    setManageFilter([...filteredOrders])

  }
  const COLUMNS = [
    {
      Header: "Sn.",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>
      },
      disableFilters: true,
      sticky: "left"
    },
    {
      Header: "Network Order Id",
      accessor: (item: any) => (item?.ondcorderid ? item?.ondcorderid : ""),
      sticky: "left"
    },
    {
      Header: "Order Create Date & Time",
      accessor: (item: any) => (item?.createdAt),
      Cell: (item: any) => format(new Date(item.value), 'yyyy-MM-dd-kk:mm:ss'),
      sticky: "left"
    },
    {
      Header: "Ageing (in days)",
      Footer: "Ageing",
      accessor: (row: any) => calculateAgeing(row),
      sticky: "left"
    },
    {
      Header: "Customer Unique Identifier[First 4 Char of Buyer Name + Last 5 Digit of Ph]",
      accessor: (item: any) => (`${item?.response?.message?.order.billing.name.slice(0, 4)}-${item?.response?.message?.order?.billing?.phone?.slice(-5)}`),
      sticky: "left"
    },
    {
      Header: "Payment Status",
      accessor: (item: any) => (item?.response.message.order.payment.status ? item?.response?.message?.order?.payment?.status : ""),
      sticky: "left"
    },
    {
      Header: "Price",
      accessor: (item: any) => (item?.response.message.order.quote.breakup[0].price.value ? item?.response.message.order.quote.breakup[0].price.value : ""),
      sticky: "left"
    },
    {
      Header: "Total Order Value",
      accessor: (item: any) => (item?.response.message.order.items[0].quantity.count ? item?.response.message.order.items[0].quantity.count : ""),
      sticky: "left"
    },
    {
      Header: "Delivery City",
      accessor: (item: any) => (item?.delivery_city ? item?.delivery_city : ""),
      sticky: "left"
    },
    {
      Header: "Delivery Pincode",
      accessor: (item: any) => (item?.response.message.order.billing.address.area_code ? item?.response.message.order.billing.address.area_code : ""),
      sticky: "left"
    },
    {
      Header: "Seller NP Order Id",
      accessor: (item: any) => (item?.shopifyorderid ? item?.shopifyorderid : ""),
      sticky: "left"
    },
    {
      Header: "NP Seller Ref Id(shopify)",
      accessor: (item: any) => (item?.shopifyorderef ? item?.shopifyorderef : ""),
      sticky: "left"
    },
    {
      Header: "Seller NP Type (MSN / ISN)",
      accessor: (a: any) => (a ? "ISN" : ""),
      sticky: "left"
    },
    // {
    //   Header: "Order Status",
    //   accessor: (item: any) => (item?.status ? item?.status : ""),
    //   sticky: "left"
    // },
    {
      id: 'OrderStatusColumn',
      Header: () => (
        <div>
          <select onChange={(e) => selectOrderStatusHandler(e)} style={{ background: "inherit", color: "white", border: "none", width: "125px", textAlign: 'center' }}>
            <option value="" style={{ background: "green", color: "white" }} selected disabled > Order Status</option>
            <option value="Accepted" style={{ background: "green", color: "white" }}  > Accepted</option>
            <option value="In-progress" style={{ background: "green", color: "white" }}  > In-Progress</option>
            <option value="Completed" style={{ background: "green", color: "white" }}  > Completed</option>
            <option value="Cancelled" style={{ background: "green", color: "white" }} > Cancelled</option>
          </select>
        </div>
      ),
      accessor: (a: any) => a?.status ? a?.status : null,
      sticky: "left"
    },
    {
      Header: "Buyer NP Name",
      accessor: (item: any) => (item?.response.context.bap_id ? item?.response.context.bap_id : ""),
      sticky: "left"
    },
    {
      Header: "Name of Seller",
      accessor: (a: any) => (a ? "The U Shop" : ""),
      sticky: "left"
    },
    {
      Header: "Network Transaction Id",
      accessor: (item: any) => (item?.response.context.transaction_id ? item?.response.context.transaction_id : ""),
      sticky: "left"
    },
    {
      Header: "SKU Name",
      accessor: (item: any) => (item?.response.message.order.quote.breakup[0].title ? item?.response.message.order.quote.breakup[0].title : ""),
      sticky: "left"
    },
    {
      Header: "SKU Code",
      accessor: (a: any) => (a?.sku ? a?.sku : ""),
      sticky: "left"
    },
    {
      Header: "Order Category (F&B/ Grocery/ Home & Decor)",
      accessor: (a: any) => (a ? "Grocery" : ""),
      sticky: "left"
    },
    // {
    //   Header: "AWB No.",
    //   accessor: (a: any) => (a?.awbid),
    //   sticky: "left"
    // }, {
    //   Header: "Logistics Service provider name",
    //   accessor: (item: any) => (item?.courier_company),
    //   sticky: "left"
    // },
    // {
    //   Header: "Warehouse Name",
    //   accessor: (a: any) => (a.status === "Accepted" || a.status === "In-progress" || a.status === "Completed") && 'JHAKAAS RETAIL PRIVATE LIMITED',
    //   sticky: "left"
    // },
    // {
    //   Header: "Warehouse Contact",
    //   accessor: (a: any) => (a.status === "Accepted" || a.status === "In-progress" || a.status === "Completed") && '18008330506',
    //   sticky: "left"
    // },
    // {
    //   Header: "Tracking URL",
    //   accessor: (item: any) => (item?.tracking_url || item?.trackurl),
    //   sticky: "left"
    // },
    {
      Header: "Ready to Ship At Date & Time",
      accessor: (item: any) => (item?.status === "Cancelled" || item?.status === 'Accepted' || item?.status === 'Completed' || item?.status === 'In-progress') && item.ready_shipped_datetime ? format(new Date(item.ready_shipped_datetime), 'yyyy-MM-dd-kk:mm:ss') : "",
      sticky: "left"
    },
    {
      Header: "Shipped At Date & Time",
      accessor: (a: any) => (a.status === "In-progress" || a.status === "Completed") && a.shipped_datetime ? format(new Date(a.shipped_datetime), 'yyyy-MM-dd-kk:mm:ss') : "",
      sticky: "left"
    },
    {
      Header: "Delivered At Date & Time",
      accessor: (a: any) => (a.status === "Completed") && a.delivered_datetime ? format(new Date(a.delivered_datetime), 'yyyy-MM-dd-kk:mm:ss') : "",
      sticky: "left"
    },
    {
      Header: "Cancelled At Date & Time",
      // accessor: (a: any) => (a.status === "Cancelled") && a.cancel_date ? format(new Date(a.cancel_date), 'yyyy-MM-dd-kk:mm:ss') : "",
      accessor: (a: any) => ((a?.status === "Cancelled") && a?.cancel_date) ? a.cancel_date : "",
      sticky: "left"
    },
    // {
    //   Header: "Cancelled By (Buyer/ Seller/ Logistics)",
    //   accessor: (item: any) => (item?.cancelled_by),
    //   sticky: "left"
    // },
    {
      Header: "Cancelled Reason Id",
      accessor: (a: any) => (a?.cancellation_reason_id),
      sticky: "left"
    },
    {
      Header: "Cancellation reason /retrun reason(Network description)",
      accessor: (a: any) => (a?.cancel_reasons),
      sticky: "left"
    },
    {
      Header: "Logistics Seller NP Name (For on-network delivery)",
      accessor: (a: any) => (a ? "OFF NETWORK" : ""),
      sticky: "left"
    },

    {
      Header: "Seller City",
      accessor: (a: any) => (a ? "Bangalore" : ""),
      sticky: "left"
    },
    {
      Header: "Seller Pincode",
      accessor: (a: any) => (a ? "560067" : ""),
      sticky: "left"
    },
    {
      Header: "Total Shipping Charges",
      accessor: (item: any) => (item?.response?.message?.order?.quote?.breakup?.slice(-1)[0]?.price?.value),
      sticky: "left"
    },
    {
      Header: "Total Order Value (Inc Shipping charges)",
      accessor: (item: any) => (item?.response?.message?.order?.quote?.price?.value),
      sticky: "left"
    },
    {
      Header: "Orders Details",
      accessor: (item: any) => (
        <div style={styles?.detailsStyle}>
          <button className="badge badge-info btn-info" data-toggle="modal"
            data-target="#myModal"
            onClick={() => handleClickPop(item?.ondcorderid)}>
            View
          </button>
          <button
            className="badge badge-outline-dark btn-outline-primary mt-2"
            // onClick={showModal}
            onClick={() => ChangeStatusPopup(item?.ondcorderid, item?.status, item?.response?.context)}
          >
            Update
          </button>
          <button
            className="badge badge-dark btn-success mt-2"
            onClick={() =>
              changeStatusseller(item?.ondcorderid, item?.response?.context)
            }
          >
            Send Status
          </button>

          <button
            className="badge badge-danger btn-danger mt-2"
            data-toggle="modal"
            data-target="#cancelProductModal"
          // onClick={() =>
          //   CancelOrderPopup(item?.ondcorderid)
          // }
          >
            Cancel
          </button>
        </div>
      ),
      sticky: "left",
    }
  ];

  const POPCOLUMNS = [
    {
      Header: "Sn.",
      accessor: (row: any, count: any) => {
        return <>{count + 1}</>
      },
      disableFilters: true,
      sticky: "left"
    },
    {
      Header: "DSL OrderId",
      accessor: (a: any) => (a?.DSorderID ? a?.DSorderID : null),
      sticky: "left"
    },
    {
      Header: "ONDC OrderId",
      accessor: (a: any) => (a?.ondcorderid ? a?.ondcorderid : null),
      sticky: "left"
    },
    {
      Header: "Item Id",
      accessor: (a: any) => (a?.itemid ? a?.itemid : null),
      sticky: "left"
    },
    {
      Header: "F_Code",
      accessor: (a: any) => (a?.f_code ? a?.f_code : null),
      sticky: "left"
    },
    {
      Header: "F_Name",
      accessor: (a: any) => (a?.f_name ? a?.f_name : null),
      sticky: "left"
    },
    {
      Header: "Cancellation reason /retrun reason(Network description)",
      accessor: (a: any) => (a?.cancellation_reason ? a?.cancellation_reason : null),
      sticky: "left"
    },
    {
      Header: "Cancelled At Date & time",
      accessor: (a: any) => (a?.cancel_date ? a?.cancel_date : null),
      sticky: "left"
    },
    {
      Header: "Cancelled_by",
      accessor: (a: any) => (a?.cancelled_by ? a?.cancelled_by : null),
      sticky: "left"
    },
    {
      Header: "Cancellation_reason_id",
      accessor: (a: any) => (a?.cancellation_reason_id ? a?.cancellation_reason_id : null),
      sticky: "left"
    },
    {
      Header: "Status",
      accessor: (a: any) => (a?.status ? a?.status : null),
      sticky: "left"
    },
    {
      Header: "Actions",
      accessor: (item: any) => (
        <div style={styles?.detailsStyle}>
          <button
            className="badge badge-danger btn-danger"
            type="submit"
            onClick={() =>
              Cancel_Item(item?.ondcorderid, item?.itemid)
            }
          >
            Cancel
          </button>
          <button className="badge badge-outline-dark btn-outline-danger mt-2"
            onClick={() =>
              changeUpdate_Unsolicated(item?.ondcorderid)
            }
          >
            Unsolicated Update
          </button>
        </div>
      ),
      sticky: "left",
    },
  ]

  const Cancel_Item = async (order_id: any, item_id: any) => {
    // console.log("nothing data");
    // console.log("orderId", order_id);
    // console.log("item_id", item_id);
    // context.message_id = uuidv4();
    const contextdata = {
      order_id,
      item_id,
      status: "Cancelled",
    };
    console.log("contaxtdata", contextdata);
    try {
      const { data } = await axios({
        url: `${baseurl}/orders/cancel-item`,
        method: "PUT",
        headers: {
          Authorization: ` ${token}`
        },
        data: contextdata,
      });

      // console.log("sas => ", data);
      toast.success("Status updated by seller app");
      // console.log("data>>>>>>", data);
      await changeUpdate_Unsolicated(order_id);
    } catch (error: any) {
      console.log("error", error);
      toast.error(`Error : ${error.message}`);
    }
  };

  // console.log("manageFilter",manageFilter)
  return (
    <>
      <ThemeMaker>
        <div className="row">
          <div className="col-md-12 pl-4 pr-4">
            <div className="card shadow mb-4">
              <div className="card-header">
                <div className="row d-flex justify-content-between">
                  <h6 className="m-0 font-weight-bold text-dark">Order List</h6>
                  <div>
                    {/* <button className="btn btn-primary" onClick={() => exportToExcel(orderList)}>Download Sheet</button> */}
                    <a href="/orders/exportcsv" className="btn btn-outline-dark" >Download Sheet</a>
                  </div>
                </div>
                <div className="row justify-content-between">
                  <div className="col-md-4 col-6">
                    <label htmlFor="start-date">Search Filter : </label>
                    <input type="search" name="manage_order" placeholder="Search" className="mr-2 form-control"
                      onChange={(event) => filterBySearch(event)}
                    />
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    <div className="col-6">
                      <label htmlFor="start_date"> Start Date : </label>
                      <input type="date" className="form-control" placeholder="Select a date" id="start_date"
                        value={startDate}
                        onChange={handleStartDateChange}
                      />
                    </div>
                    <div className="col-5">
                      <label htmlFor="end_date"> End Date : </label>
                      <input type="date" className="form-control"
                        value={endDate}
                        onChange={handleEndDateChange} id="end_date"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="table-responsive table-bordered">
                  {loading ? (
                    <Loader />
                  ) : (
                    <><Paginated data={manageFilter} columns={COLUMNS} /></>
                    //                   <table
                    //                   className="table table-bordered"
                    //                   id="dataTable"
                    //                   width="100%"
                    //                 >
                    //                   <thead>
                    //                     <tr>
                    //                       <th>Sn</th>
                    //                       <th>Network Order Id</th>
                    //                       <th>Order Create Date & Time</th>
                    //                       <th>
                    //                         Customer Unique Identifier[First 4 Char of Buyer
                    //                         Name + Last 5 Digit of Ph]
                    //                       </th>
                    //                       <th>Payment Status</th>
                    //                       <th>Price</th>
                    //                       <th>Total Order Value</th>
                    //                       <th>Delivery City</th>
                    //                       <th>Delivery Pincode</th>
                    //                       <th>Seller NP Order Id</th>
                    //                       <th>NP Seller Ref Id(shopify)</th>
                    //                       <th>Seller NP Type (MSN / ISN)</th>
                    //                       <th>Order Status</th>
                    //                       <th>Buyer NP Name</th>
                    //                       <th>Name of Seller</th>
                    //                       <th>Network Transaction Id</th>
                    //                       <th>SKU Name</th>
                    //                       <th>SKU Code</th>
                    //                       <th>Order Category (F&B/ Grocery/ Home & Decor)</th>
                    //                       <th>Ready to Ship At Date & Time</th>
                    //                       <th>Shipped At Date & Time</th>
                    //                       <th>Delivered At Date & Time</th>
                    //                       <th>Cancelled At Date & Time</th>
                    //                       <th>Cancelled Reason Id</th>
                    //                       <th>
                    //                         Logistics Seller NP Name (For on-network delivery)
                    //                       </th>
                    //                       {/* <th>Delivery Type (On-network/ Off-network)</th>
                    // <th>
                    //   Logistics Network Order Id (For on-network delivery)
                    // </th>
                    // <th>
                    //   Logistics Network Transaction Id (For on-network
                    //   delivery)
                    // </th>
                    // <th>Cancelled At Date & Time</th>
                    // <th>Cancelled By (Buyer/ Seller/ Logistics)</th> */}
                    //                       {/* <th>Cancellation Reason (Error Description)</th>
                    // <th>Cancellation Remark</th> */}
                    //                       <th>Seller City</th>
                    //                       <th>Seller Pincode</th>
                    //                       <th>Total Shipping Charges</th>
                    //                       <th>Total Order Value (Inc Shipping charges)</th>
                    //                       <th>Orders Details</th>
                    //                     </tr>
                    //                   </thead>
                    //                   <tfoot></tfoot>
                    //                   <tbody>
                    //                     {orderList.map((item: any, index) => {
                    //                       const rr = item?.response?.message?.order.billing.phone
                    //                         .split("")
                    //                         .reverse()
                    //                         .slice(0, 5);
                    //                       return (
                    //                         <tr key={item._id}>
                    //                           <td>{index + 1}</td>

                    //                           <td>{item?.ondcorderid}</td>
                    //                           <td>{format(new Date(item?.createdAt), 'yyyy-MM-dd-kk:mm:ss')}</td>
                    //                           <td>
                    //                             {item?.response?.message?.order.billing.name.slice(0, 4)}-{String(rr).split(",").reverse()}
                    //                           </td>
                    //                           <td>
                    //                             {item?.response?.message?.order?.payment
                    //                               ?.status}
                    //                           </td>

                    //                           <td>
                    //                             {item?.response?.message?.order?.quote
                    //                               ?.breakup[0].price?.value}
                    //                           </td>

                    //                           <td>
                    //                             {item?.response.message.order.items[0].quantity
                    //                               .count}
                    //                           </td>

                    //                           <td>{item?.delivery_city}</td>

                    //                           <td>
                    //                             {item?.response?.message?.order?.billing
                    //                               ?.address?.area_code}
                    //                           </td>

                    //                           <td>{item?.shopifyorderid}</td>
                    //                           <td>{item?.shopifyorderef}</td>
                    //                           <td>{"ISN"}</td>

                    //                           <td>{item?.status}</td>
                    //                           <td>{item?.response?.context?.bap_id}</td>
                    //                           <td>{"The U Shop"}</td>
                    //                           <td>{item?.response?.context?.transaction_id}</td>
                    //                           <td>
                    //                             {item?.response?.message?.order?.quote
                    //                               .breakup[0].title}
                    //                           </td>
                    //                           <td>{item?.sku}</td>
                    //                           <td>{"Grocery"}</td>
                    //                           <td>{(item?.status == 'Cancelled' || item?.status == 'Accepted' || item?.status == 'In-progress' || item?.status == 'Completed') && item?.ready_shipped_datetime ? format(new Date(item.ready_shipped_datetime), 'yyyy-MM-dd-kk:mm:ss') : ""}</td>
                    //                           <td>{(item?.status == 'In-progress' || item?.status == 'Completed') && item?.shipped_datetime ? format(new Date(item?.shipped_datetime), 'yyyy-MM-dd-kk:mm:ss') : ""}</td>
                    //                           <td>{(item?.status == 'Completed') && item.delivered_datetime ? format(new Date(item.delivered_datetime), "yyyy-MM-dd-kk:mm:ss") : ""}</td>
                    //                           <td>
                    //                             {(item?.status == "Cancelled") && item?.cancel_date ? item?.cancel_date : ""}</td>
                    //                           <td>{item?.cancellation_reason_id}</td>
                    //                           {/* <td>{item?.ready_shipped_time}</td>
                    //             <td>{item?.shipped_time}</td>
                    //             <td>{item?.delivered_date}</td> */}
                    //                           <td>{"OFF NETWORK"}</td>
                    //                           {/* <td>{"null"}</td>
                    //             <td>{"null"}</td>
                    //             <td>{"null"}</td>
                    //             <td>
                    //               {item.status == "Cancelled"
                    //                 ? item.cancel_date
                    //                 : "null"}
                    //             </td>
                    //             <td>
                    //               {item.status == "Cancelled" ||
                    //               item.status == "Liquidated"
                    //                 ? item.cancelled_by
                    //                 : "null"}
                    //             </td> */}
                    //                           {/* <td>{"null"}</td>
                    //             <td>{"null"}</td> */}
                    //                           <td>{"Bangalore"}</td>
                    //                           <td>{"560067"}</td>
                    //                           <td>
                    //                             {item?.response?.message?.order?.quote
                    //                               ?.breakup[1].price?.value}
                    //                           </td>
                    //                           <td>
                    //                             {item?.response?.message?.order?.quote?.price
                    //                               ?.value}
                    //                           </td>

                    //                           <td>
                    //                             <button
                    //                               className="btn btn-info btn-lg"
                    //                               data-toggle="modal"
                    //                               data-target="#myModal"
                    //                               onClick={() => handleClickPop(item?.ondcorderid)}
                    //                             >
                    //                               View
                    //                             </button>

                    //                             <button
                    //                               className="btn btn-outline-dark mt-3"
                    //                               onClick={() => ChangeStatusPopup(
                    //                                 item?.ondcorderid,
                    //                                 item?.status,
                    //                                 item?.response?.context
                    //                               )}
                    //                             >
                    //                               Update
                    //                             </button>

                    //                             <button
                    //                               className="btn btn-outline-dark mt-3"
                    //                               onClick={() => changeStatusseller(
                    //                                 item?.ondcorderid,
                    //                                 item?.response?.context
                    //                               )}
                    //                             >
                    //                               Send Status
                    //                             </button>

                    //                             <button
                    //                               className="btn btn-danger mt-3"
                    //                               onClick={() => CancelOrderPopup(
                    //                                 item?.ondcorderid,
                    //                                 item?.shopifyorderid
                    //                               )
                    //                                 // CancelOrder(
                    //                                 //   item?.shopifyorderid,
                    //                                 //   item?.ondcorderid
                    //                                 // )
                    //                               }
                    //                             >
                    //                               Cancel
                    //                             </button>
                    //                           </td>
                    //                         </tr>
                    //                       );
                    //                     })}
                    //                   </tbody>
                    //                 </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="popcolomn">
          <div className="modal fade" id="myModal" role="dialog">
            <div className="modal-dialog ">
              <div className="modal-content container">
                <div className="modal-header">
                  <h6 className="m-0 font-weight-bold text-dark">Orders Details</h6>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>
                <div className="popupColomn">
                  <div className="table-responsive table-striped table-bordered">
                    <div>
                      {loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
                          <CircularProgress />
                        </div>
                      ) : (
                        orderPopup && <Paginated data={orderPopup} columns={POPCOLUMNS} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div>
          <div className="modal fade" id="myModal" role="dialog">
            <div className="modal-dialog ">
              <div className="modal-content container">
                <div className="modal-header">
                  <h2>Orders Details</h2>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>

                <div className="modal-body">
                  <div className="popupColomn">
                    <div className="table-responsive table-striped table-bordered">
                      <div>
                        {loading ? (
                          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
                            <CircularProgress />
                          </div>
                        ) : (
                          orderPopup && <Paginated data={orderPopup} columns={POPCOLUMNS} />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <table
                    className="table table-bordered"
                    id="dataTable"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>Sn</th>
                        <th>Shopify OrderId</th>
                        <th>ONDC OrderId</th>
                        <th>Item Id</th>
                        <th>F_Code</th>
                        <th>F_Name</th>
                        <th>
                          Cancellation reason /retrun reason(Network
                          description)
                        </th>
                        <th>Cancelled At Date & time</th>
                        <th>Cancelled_by</th>
                        <th>Cancellation_reason_id</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tfoot></tfoot>
                    <tbody>
                      {orderPopup.map((item: any, index) => (
                        <tr key={item._id}>
                          <td>{index + 1}</td>

                          <td>{item?.shopifyorderid}</td>
                          <td>{item?.ondcorderid}</td>
                          <td>{item?.itemid}</td>
                          <td>{item?.f_code}</td>
                          <td>{item?.f_name}</td>
                          <td>{item?.cancellation_reason}</td>
                          <td>{item?.cancel_date}</td>
                          <td>{item?.cancelled_by}</td>
                          <td>{item?.cancellation_reason_id}</td>
                          <td>{item?.status}</td>
                          <td>
                            <button
                              className="btn btn-danger "
                              type="submit"
                              onClick={() =>
                                Cancel_Item(item?.ondcorderid, item?.itemid)
                              }
                            >
                              Cancel
                            </button>
                            <button
                              className="btn btn-danger mt-3"
                              onClick={() =>
                                changeUpdate_Unsolicated(item?.ondcorderid)
                              }
                            >
                              Unsolicated Update
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      {orderStatusPopup ? (
          <div className="orderStatuspopup text-center mx-auto p-5 border shadow bg-light position-fixed top-50 end-0 translate-middle-y">
            <div>
              <div className="selectStatus">
                <p>{`Update order : ${status}`}</p>
                <select
                  className="btn btn-secondary"
                  value={status}
                  onChange={(event: any) => {
                    console.log("event", event);
                    setstatus(event.target.value);
                  }}
                >
                  <option value="Created" disabled={dynamicValue !== "Created"}>
                    Created
                  </option>
                  <option
                    value="Accepted"
                    disabled={
                      dynamicValue !== "Created" &&
                      dynamicValue !== "Accepted" &&
                      (dynamicValue === "In-progress" ||
                        dynamicValue === "Completed")
                    }
                  >
                    Accepted
                  </option>
                  <option
                    value="In-progress"
                    disabled={
                      dynamicValue !== "Created" &&
                      dynamicValue !== "In-progress"
                    }
                  >
                    In-progress
                  </option>
                  <option
                    value="Completed"
                    disabled={
                      dynamicValue !== "Accepted" &&
                      dynamicValue === "Completed"
                    }
                  >
                    Completed
                  </option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>
              <div className="selectStatus">
                <p className="m-2">{`Update fulfillment : ${fullFillmentStatus}`}</p>
                <select
                  className="btn btn-secondary"
                  value={fullFillmentStatus}
                  onChange={(event: any) => {
                    console.log("event", event);
                    setFullFillmentStatus(event.target.value);
                  }}
                >
                  <option value="Pending" disabled={status !== "Accepted"}>
                    Pending
                  </option>
                  <option
                    value="Order-picked-up"
                    disabled={status !== "In-progress"}
                  >
                    Order picked up
                  </option>
                  <option
                    value="Out-for-delivery"
                    disabled={status !== "In-progress"}
                  >
                    Out for delivery
                  </option>
                  <option
                    value="Order-delivered"
                    disabled={status !== "Completed"}
                  >
                    Order delivered
                  </option>
                  <option value="Cancelled" disabled={status !== "Cancelled"}>
                    Cancelled
                  </option>
                </select>
              </div>
              <div className="selectStatus">
                <p className="m-2">{`Update fulfillment : ${timestamp}`}</p>
                <input type="text"
                  placeholder="YYYY-MM-DDThh:mm:ssTZD"
                  value={timestamp}
                  onChange={(event: any) => {
                    setTimestamp(event.target.value);
                  }}
                />
              </div>
            </div>
            <button
              className="btn btn-primary d-flex m-auto updateButton"
              type="submit"
              onClick={changeStatus}
            >
              Update
            </button>
            <button
              className="border-0 px-3 py-2 rounded-circle "
              onClick={closePop}
            >
              x
            </button>
          </div>
        ) : null} 
        {/* {orderStatusPopup ? (
          <AntModal open={orderStatusPopup} onOk={handleOk} onCancel={handleCancel}
            width={500}
            footer={null}>
            <h6 className="m-0 font-weight-bold text-dark text-left ">Change Status</h6>
            <hr />
            <div className="text-center">
              <div className="selectStatus">
                <p>{`Update order : ${status}`}</p>
                <select
                  className="btn btn-secondary"
                  value={status}
                  onChange={(event: any) => {
                    console.log("event", event);
                    setstatus(event.target.value);
                  }}
                >
                  <option value="Created" disabled={dynamicValue !== "Created"}>
                    Created
                  </option>
                  <option
                    value="Accepted"
                    disabled={
                      dynamicValue !== "Created" &&
                      dynamicValue !== "Accepted" &&
                      (dynamicValue === "In-progress" ||
                        dynamicValue === "Completed")
                    }
                  >
                    Accepted
                  </option>
                  <option
                    value="In-progress"
                    disabled={
                      dynamicValue !== "Created" &&
                      dynamicValue !== "In-progress"
                    }
                  >
                    In-progress
                  </option>
                  <option
                    value="Completed"
                    disabled={
                      dynamicValue !== "Accepted" &&
                      dynamicValue === "Completed"
                    }
                  >
                    Completed
                  </option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>
              <div className="selectStatus">
                <p className="m-2">{`Update fulfillment : ${fullFillmentStatus}`}</p>
                <select
                  className="btn btn-secondary"
                  value={fullFillmentStatus}
                  onChange={(event: any) => {
                    console.log("event", event);
                    setFullFillmentStatus(event.target.value);
                  }}
                >
                  <option value="Pending" disabled={status !== "Accepted"}>
                    Pending
                  </option>
                  <option
                    value="Order-picked-up"
                    disabled={status !== "In-progress"}
                  >
                    Order picked up
                  </option>
                  <option
                    value="Out-for-delivery"
                    disabled={status !== "In-progress"}
                  >
                    Out for delivery
                  </option>
                  <option
                    value="Order-delivered"
                    disabled={status !== "Completed"}
                  >
                    Order delivered
                  </option>
                  <option value="Cancelled" disabled={status !== "Cancelled"}>
                    Cancelled
                  </option>
                </select>
              </div>
              <div className="selectStatus">
                <p className="m-2">{`Update fulfillment : ${timestamp}`}</p>
                <input type="text"
                  placeholder="YYYY-MM-DDThh:mm:ssTZD"
                  value={timestamp}
                  onChange={(event: any) => {
                    setTimestamp(event.target.value);
                  }}
                />
              </div>
            </div>
            <hr />
            <div className="ant-modal-footer">
              <button
                className="btn btn-primary btn-sm"
                type="submit"
                onClick={async () => { await changeStatus(); handleOk(); }}>
                {updating ? 'Updating...' : 'Update'}
              </button>
            </div>
          </AntModal>
        ) : null} */}

        {cancelOrderPopup ? (
          <div className="cancelOrderPopup text-center mx-auto p-5 border shadow bg-light position-fixed top-50 end-0 translate-middle-y">
            <div>
              <div className="selectStatus">
                <p>{`Select a Reason for cancellation : ${reason_code}`}</p>
                <select
                  className="btn btn-secondary"
                  value={reason_code}
                  onChange={(event: any) => {
                    console.log("event", event);
                    setreason_code(event.target.value);
                  }}
                >
                  <option value="002">One or more items in the Order not available</option>
                  <option value="005">Merchant rejected the order</option>
                  <option value="008">Order not ready for pickup</option>
                  <option value="011">Buyer not found or cannot be contacted</option>
                  <option value="013">Buyer refused to accept delivery</option>
                  <option value="014">Address not found</option>
                  <option value="015">Buyer not available at location</option>
                  <option value="016">Accident / rain / strike / vehicle issues</option>
                  <option value="017">Order delivery delayed or not possible</option>
                  <option value="018">Delivery pin code not serviceable</option>
                  <option value="019">Pickup pin code not serviceable</option>
                </select>
              </div>
            </div>
            <button
              className="btn btn-primary d-flex m-auto updateButton"
              type="submit"
              onClick={cancelOrder}
            >
              Update
            </button>
            <button
              className="border-0 px-3 py-2 rounded-circle "
              onClick={closeCancelPop}
            >
              x
            </button>
          </div>
        ) : null}
      </ThemeMaker>
    </>
  );
};

export default OrderList;
